import React, { useEffect, useState } from "react";
import "./FloorDetailsStyles.scss";
import close from "../../../../assets/close.png";
import back from "../../../../assets/arrow.png";

import email from "../../../../assets/email.png";
import more from "../../../../assets/more.png";
import edit from "../../../../assets/pencil.png";
import tag from "../../../../assets/tag.png";
// import man from "../../../../assets/man.png";
// import notes from "../../../../assets/notes.png";
// import note from "../../../../assets/notepad.png";
// import comment from "../../../../assets/comment.png";
import calendar from "../../../../assets/clIC.png";
// import time from "../../../../assets/alarm.svg";
import covers from "../../../../assets/friends.png";
import duration from "../../../../assets/duration.png";
import table from "../../../../assets/cover.svg";
import status from "../../../../assets/status.png";
import est from "../../../../assets/alarm.svg";
import reserve from "../../../../assets/restaurant.svg";
import Button from "../../../../components/button/Button";
import moment from "moment";
import NumberInput from "../../../../components/ReservationComponents/numberInput/NumberInput";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllocatedTables,
  setBookingStatus,
  setBookingTags,
  setFloorData,
} from "../../../../../application/reducer/bookingSlice";
import DurationInput from "../../../../components/ReservationComponents/numberInput/DurationInput";
import TimeInput from "../../../../components/ReservationComponents/numberInput/TimeInput";
import DateModal from "../../../../components/ReservationComponents/modal/dateModal/DateModal";
import StatusModal from "../../../../components/ReservationComponents/modal/statusModal/StatusModal";
import DurationModal from "../../../../components/ReservationComponents/modal/durationModal/DurationModal";
import TimeModal from "../../../../components/ReservationComponents/modal/timeModal/TimeModal";
import CoverModal from "../../../../components/ReservationComponents/modal/coverModal/CoverModal";
import {
  getFloorDetails,
  getStatus,
  getTags,
  updateReservation,
} from "../../../../../infra/apis/bookings/requests/Booking";
// import NotesModal from "../../../../components/ReservationComponents/modal/noteModal/NotesModal";
// import TagModal from "../../../../components/ReservationComponents/modal/tagModal/TagModal";
import TextArea from "../../../../components/textarea/TextArea";
import { RootState } from "../../../../../application/store";
import TableErrorModal from "../../../../components/ReservationComponents/errorModal/TableErrorModal";
import SaveModal from "../../../../components/ReservationComponents/errorModal/SaveModal";
import Alert from "../tableDetails/Alert";
interface FloorDetailsProps {
  isModalOpen: boolean;
  onClose: () => void;
  tableData: any;
  setSelectedTables: React.Dispatch<React.SetStateAction<boolean>>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  date: any;
  setDate: React.Dispatch<React.SetStateAction<any>>;
  bookingTime: string;
  setBookingTime: React.Dispatch<React.SetStateAction<string>>;
  guest: number;
  setGuest: React.Dispatch<React.SetStateAction<number>>;
  setSelectedTable: React.Dispatch<React.SetStateAction<any>>;
  setIsUpdateSuccess: React.Dispatch<React.SetStateAction<boolean>>;
}

const FloorDetails: React.FC<FloorDetailsProps> = ({
  isModalOpen,
  onClose,
  tableData,
  setSelectedTables,
  setIsModalOpen,
  date,
  setDate,
  bookingTime,
  setBookingTime,
  guest,
  setGuest,
  setSelectedTable,
  setIsUpdateSuccess,
}) => {
  const [time, setTime] = useState<string>("0 min");
  const [interval, setInterval] = useState<number>(15);
  const [statusValue, setStatusValue] = useState<any>(
    tableData ? tableData.StatusInfo : ""
  );
  const [request, setRequest] = useState<string>(
    tableData ? tableData.ReservationNotes : ""
  );
  const [tags, setTagsData] = useState<any[]>(
    tableData ? (tableData.Tags !== null ? tableData.Tags : []) : []
  );
  const [isRequestOpen, setRequestOpen] = useState<boolean>(false);
  const [isStatusOpen, setStatusOpen] = useState<boolean>(false);
  const [isCoversOpen, setCoversOpen] = useState<boolean>(false);
  const [isDurationOpen, setDurationOpen] = useState<boolean>(false);
  const [isDatOpen, setDateOpen] = useState<boolean>(false);
  const [isTimeOpen, setTimeOpen] = useState<boolean>(false);
  const [isTagOpen, setTagOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isTableError, setIsTableError] = useState<boolean>(false);
  const [isSaveOpen, setIsSaveOpen] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState("");

  const floorData = useSelector((state: RootState) => state.booking.floorData);
  const convertTimeToMinutes = (timeString: string) => {
    let totalMinutes = 0;

    // Use a regular expression to extract the numbers and their units
    const timeParts = timeString.match(/(\d+)\s*(h|min)/g);

    if (timeParts) {
      timeParts.forEach((part) => {
        if (part.includes("h")) {
          const hours = parseInt(part.replace("h", ""), 10);
          totalMinutes += hours * 60;
        } else if (part.includes("min")) {
          const minutes = parseInt(part.replace("min", ""), 10);
          totalMinutes += minutes;
        }
      });
    }

    return totalMinutes;
  };
  const convertMinutesToTime = (totalMinutes: any) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    let timeString = "";
    if (hours > 0) {
      timeString += `${hours} h `;
    }
    if (minutes > 0) {
      timeString += `${minutes} mins`;
    }

    return timeString.trim();
  };
  useEffect(() => {
    getStatus().then((response: any) => {
      dispatch(setBookingStatus(response));
    });
    getTags().then((response: any) => {
      dispatch(setBookingTags(response));
    });
  }, []);
  useEffect(() => {
    let dateFormat = moment(date).format("YYYY-MM-DD");
    getFloorDetails(dateFormat).then((response: any) => {
      const sortedFloor: any = [...response].sort(
        (a, b) => a.Priority - b.Priority
      );
      dispatch(setFloorData(sortedFloor));
    });
  }, [date]);
  useEffect(() => {
    setTime(convertMinutesToTime(tableData?.BookingDetails?.Duration));
    setGuest(tableData?.BookingDetails?.NumberOfPeople);
    setStatusValue(tableData?.StatusInfo);
    setBookingTime(tableData?.BookingDetails?.BookingTime);
    let dateValue = new Date(tableData?.BookingDetails?.BookingDate);
    setDate(
      new Date(
        dateValue.getUTCFullYear(),
        dateValue.getUTCMonth(),
        dateValue.getUTCDate(),
        dateValue.getUTCHours(),
        dateValue.getUTCMinutes(),
        dateValue.getUTCSeconds()
      )
    );
    setRequest(tableData.ReservationNotes);
    setTagsData(tableData.Tags !== null ? tableData.Tags : []);
  }, []);
  useEffect(() => {
    let bookingDetails = {
      ...tableData.BookingDetails,
      BookingDate: moment(date).format("YYYY-MM-DDTHH:mm:ss[Z]"),
      BookingTime: bookingTime,
      Duration: convertTimeToMinutes(time),
      NumberOfPeople: guest,
    };
    let newTableDate = {
      ...tableData,
      Status: statusValue?.DisplayName,
      StatusInfo: statusValue,
      BookingDetails: bookingDetails,
    };
    setSelectedTable(newTableDate);
  }, [time, date, guest, bookingTime, statusValue]);
  const convertTimeToMinutesString = (timeString: any) => {
    const [hours, minutes] = timeString.split(":").map(Number);
    return hours * 60 + minutes;
  };
  const isTimeMatchingReservationWithDuration = (
    time: string,
    reservation: any
  ): boolean => {
    const givenTimeInMinutes = convertTimeToMinutesString(time);
    const bookingTimeInMinutes = convertTimeToMinutesString(
      reservation?.BookingDetails?.BookingTime
    );
    const endTimeInMinutes =
      bookingTimeInMinutes + reservation?.BookingDetails?.Duration;

    return (
      givenTimeInMinutes >= bookingTimeInMinutes &&
      givenTimeInMinutes < endTimeInMinutes
    );
  };
  const isTimeMatchingReservation = () => {
    let reservations = floorData
      .flatMap((item: any) =>
        item.Tables.flatMap((res: any) => res.Reservations)
      )
      .filter((res: any) => res.BookingID !== tableData.BookingID);
    if (reservations && reservations.length > 0) {
      return reservations.some((reservation: any) => {
        return reservation.AssetAllocation?.some((res: any) => {
          return tableData?.AssetAllocation.some((table: any) => {
            let dateValue = new Date(reservation?.BookingDetails?.BookingDate);

            let resDate = new Date(
              dateValue.getUTCFullYear(),
              dateValue.getUTCMonth(),
              dateValue.getUTCDate(),
              dateValue.getUTCHours(),
              dateValue.getUTCMinutes(),
              dateValue.getUTCSeconds()
            );
            return (
              res.RefNo === table.RefNo &&
              moment(resDate).format("DD-MM-yyyy") ===
                moment(date).format("DD-MM-yyyy") &&
              isTimeMatchingReservationWithDuration(bookingTime, reservation)
            );
          });
        });
      });
    }
  };
  useEffect(() => {
    setIsTableError(isTimeMatchingReservation() || false);
  }, [bookingTime, date, floorData]);
  const getMaxCapacitySum = () => {
    let totalCapacity = 0;
    for (const table of tableData?.AssetAllocation) {
      totalCapacity += table.Capacity.Max;
    }
    return totalCapacity;
  };
  useEffect(() => {
    if (tableData?.AssetAllocation.length === 0) {
      setIsAlertVisible("danger");
    } else if (
      getMaxCapacitySum() < guest &&
      tableData?.AssetAllocation.length > 0
    ) {
      setIsAlertVisible("warning");
    } else if (
      getMaxCapacitySum() / 2 > guest &&
      tableData?.AssetAllocation.length > 0
    ) {
      setIsAlertVisible("warning-2");
    } else {
      setIsAlertVisible("");
    }
  }, [guest, tableData?.AssetAllocation]);
  const dispatch = useDispatch();

  const [tab, setTab] = useState<string>("DETAILS");
  if (!isModalOpen) {
    return null;
  }
  const getAlert = () => {
    if (isAlertVisible === "danger") {
      return <Alert type='danger' content='You need to select a table.' />;
    } else if (isAlertVisible === "warning") {
      return <Alert type='warning' content={`Tables are over utilized.`} />;
    } else if (isAlertVisible === "warning-2") {
      return (
        <Alert
          type='warning'
          content={`Tables are underutilized. Maximum capacity is ${getMaxCapacitySum()}`}
        />
      );
    }
  };
  const getError = () => {
    return (
      <Alert
        type='danger'
        content={`The selected table is not available for the chosen booking time ${bookingTime}. Please select a different table or time slot`}
      />
    );
  };
  const detail = [
    {
      head: "Date",
      sub: moment(date).format("ddd,MMMM DD"),
      icon: calendar,
      isOpen: isDatOpen,
    },
    {
      head: "Time",
      sub: (
        <TimeInput
          value={bookingTime}
          setValue={setBookingTime}
          setTimeOpen={() => {
            setStatusOpen(false);
            setDateOpen(false);
            setTimeOpen(!isTimeOpen);
            setTagOpen(false);
            setCoversOpen(false);
            setDurationOpen(false);
            setRequestOpen(false);
          }}
        />
      ),
      icon: est,
      isOpen: isTimeOpen,
    },
    {
      head: "Party Size",
      sub: (
        <NumberInput
          value={guest}
          setValue={setGuest}
          setCoversOpen={() => {
            setStatusOpen(false);
            setDateOpen(false);
            setTimeOpen(false);
            setTagOpen(false);
            setCoversOpen(!isCoversOpen);
            setDurationOpen(false);
            setRequestOpen(false);
          }}
        />
      ),
      icon: covers,
      isOpen: isCoversOpen,
    },
    {
      head: "Duration",
      sub: (
        <DurationInput
          value={time}
          setValue={setTime}
          interval={interval}
          setDurationOpen={() => {
            setStatusOpen(false);
            setDateOpen(false);
            setTimeOpen(false);
            setTagOpen(false);
            setCoversOpen(false);
            setDurationOpen(!isDurationOpen);
            setRequestOpen(false);
          }}
        />
      ),
      icon: duration,
      isOpen: isDurationOpen,
    },
    {
      head: "Table",
      sub: (
        <div style={{ columnGap: "0.5rem", display: "flex" }}>
          {tableData?.AssetAllocation?.map((table: any) => {
            return <div>{table.Name}</div>;
          })}
        </div>
      ),
      icon: table,
    },
    {
      head: "Status",
      sub: (
        <div
          style={{
            color: statusValue?.Color,
            display: "flex",
            alignItems: "center",
            columnGap: "5px",
          }}
        >
          <img
            alt=''
            src={statusValue?.Icon ? statusValue?.Icon : statusValue?.Image}
            style={{ height: "14px" }}
          />
          <img />
          {statusValue?.DisplayName}
        </div>
      ),
      icon: status,
      isOpen: isStatusOpen,
    },
    // {
    //   head: "Wait Est.",
    //   sub: "0 mins",
    //   icon: est,
    // },
    {
      head: "Reserve ID",
      sub: `# ${tableData.ConfirmationNumber}`,
      icon: reserve,
    },
  ];
  const data = [
    // {
    //   head: "Guest Tags",
    //   sub: "Select tags",
    //   icon: tag,
    // },
    // {
    //   head: "Tags",
    //   sub: <div>{tags.map((tag) => tag.Name)}</div>,
    //   icon: notes,
    //   hide: false,
    //   isOpen: isTagOpen,
    // },
    {
      head: "Notes",
      sub: isRequestOpen ? request : request === "" ? "None" : request,
      icon: tag,
      hide: false,
      isOpen: isRequestOpen,
    },
    // {
    //   head: "Private Comments",
    //   sub: "Write comments only your team can see",
    //   icon: comment,
    // },
    // {
    //   head: "Guest Notes",
    //   sub: "Add internal guest notes here",
    //   icon: notes,
    // },
    // {
    //   head: "Employee",
    //   sub: "Select reservation taker",
    //   icon: man,
    // },
    // {
    //   head: "Fields:",
    //   sub: "Add additional details",
    //   icon: note,
    // },
  ];
  const profileDetails = [
    {
      head: "First Name",
      sub: tableData.CustomerDetails.FirstName,
    },
    {
      head: "Last Name",
      sub: tableData.CustomerDetails.LastName,
    },
    {
      head: "Gender",
      sub: "---",
    },
    {
      head: "Date of Birth",
      sub: "---",
    },
    {
      head: "Anniversary",
      sub: "---",
    },
    {
      head: "Title",
      sub: "---",
    },
    {
      head: "Phone",
      sub: `${tableData.CustomerDetails.ContactDetails[0].PhoneCode} ${tableData.CustomerDetails.ContactDetails[0].Number}`,
    },
    {
      head: "Email",
      sub: tableData.CustomerDetails.Email,
    },
    {
      head: "Job Title",
      sub: "---",
    },
    {
      head: "Company",
      sub: "---",
    },
  ];
  const profileData = [
    {
      head: "Total Spend",
      sub: 0,
    },
    {
      head: "Spend Per Cover",
      sub: 0,
    },
    {
      head: "Upcoming Reservations",
      sub: 1,
    },
    {
      head: "Materialised Reservations",
      sub: 0,
    },
    {
      head: "Denied",
      sub: 0,
    },
    {
      head: "No Show",
      sub: 0,
    },
    {
      head: "Cancelled",
      sub: 0,
    },
    {
      head: "Review",
      sub: 0,
    },
  ];
  const handleItemOnClick = (item: string) => {
    switch (item) {
      case "Status":
        setDateOpen(false);
        setTimeOpen(false);
        setTagOpen(false);
        setCoversOpen(false);
        setDurationOpen(false);
        setRequestOpen(false);
        setStatusOpen(!isStatusOpen);
        break;
      case "Date":
        setTimeOpen(false);
        setTagOpen(false);
        setCoversOpen(false);
        setDurationOpen(false);
        setRequestOpen(false);
        setStatusOpen(false);
        setDateOpen(!isDatOpen);
        break;
      case "Time":
        setTagOpen(false);
        setCoversOpen(false);
        setDurationOpen(false);
        setRequestOpen(false);
        setStatusOpen(false);
        setDateOpen(false);
        setTimeOpen(!isTimeOpen);
        break;
      case "Tags":
        setCoversOpen(false);
        setDurationOpen(false);
        setRequestOpen(false);
        setStatusOpen(false);
        setDateOpen(false);
        setTimeOpen(false);
        setTagOpen(!isTagOpen);
        break;
      case "Notes":
        setStatusOpen(false);
        setDateOpen(false);
        setTimeOpen(false);
        setTagOpen(false);
        setCoversOpen(false);
        setDurationOpen(false);
        setRequestOpen(!isRequestOpen);
        break;
      default:
        break;
    }
  };
  const getLeftContent = () => {
    if (tab === "DETAILS") {
      return (
        <div className='inner-container'>
          {detail.map((item) => {
            return (
              <>
                {item.head === "Table" && isTableError && (
                  <div className='alert'>{getError()}</div>
                )}
                {item.head === "Table" && isAlertVisible !== "" && (
                  <div className='alert'>{getAlert()}</div>
                )}

                <div
                  className={`item ${item.isOpen ? "item-sel" : ""}`}
                  onClick={() => {
                    if (item.head === "Table") {
                      dispatch(setAllocatedTables(tableData?.AssetAllocation));
                      setSelectedTables(true);
                      setIsModalOpen(false);
                    }
                    if (item.head === "Date" || item.head === "Status") {
                      handleItemOnClick(item.head);
                    }
                  }}
                >
                  <div className='det'>
                    <div className='head'>
                      <img alt='' src={item.icon} />
                      {item.head}
                    </div>
                    <div className='sub'>{item.sub}</div>
                  </div>
                  {item.head !== "Party Size" &&
                    item.head !== "Duration" &&
                    item.head !== "Time" &&
                    item.head !== "Reserve ID" && (
                      <div className='arrow-cont'>
                        <img alt='' src={back} />
                      </div>
                    )}
                </div>
              </>
            );
          })}
        </div>
      );
    } else {
      return (
        <div className='inner-container'>
          {profileDetails.map((item) => {
            return (
              <div className='item'>
                <div className='det'>
                  <div className='head'>{item.head}</div>
                  <div className='sub-gr'>{item.sub}</div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  };
  const getRightContent = () => {
    if (tab === "DETAILS") {
      return (
        <div className='inner-container'>
          {data.map((item) => {
            return (
              <div>
                <div
                  className='item'
                  onClick={() => handleItemOnClick(item.head)}
                >
                  <div className='det'>
                    <div className='head'>
                      <img alt='' src={item.icon} />
                      {item.head}
                    </div>
                    <div className='sub'> {item.sub}</div>
                  </div>
                  <div className='arrow-cont'>
                    <img alt='' src={back} />
                  </div>
                </div>
                {isRequestOpen && (
                  <div className='content'>
                    <TextArea
                      label='Add Note'
                      value={request}
                      onChange={(value) => {
                        setRequest(value);
                      }}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div className='inner-container'>
          {profileData.map((item) => {
            return (
              <div className='item'>
                <div className='det'>
                  <div className='head'>{item.head}</div>
                  <div className='sub'>{item.sub}</div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  };
  const handleSave = (from: string) => {
    if (!isTableError) {
      let bookingDetails = {
        ...tableData.BookingDetails,
        BookingDate: moment(date).format("YYYY-MM-DDTHH:mm:ss[Z]"),
        BookingTime: bookingTime,
        Duration: convertTimeToMinutes(time),
        NumberOfPeople: guest,
      };
      let newTableDate = {
        ...tableData,
        Status: statusValue?.DisplayName,
        StatusInfo: statusValue,
        BookingDetails: bookingDetails,
      };

      if (from === "save") {
        setIsSaveLoading(true);
      } else {
        setIsLoading(true);
      }

      updateReservation(newTableDate).then(() => {
        dispatch(setAllocatedTables([]));
        if (from === "save") {
          setIsSaveLoading(false);
          setIsSaveOpen(false);
          setIsModalOpen(false);
        } else {
          setIsLoading(false);
          setIsModalOpen(false);
        }
        setIsUpdateSuccess(true);
      });
    }
  };
  return (
    <div
      className='FloorDetails-overlay'
      onClick={() => {
        dispatch(setAllocatedTables([]));
        onClose();
      }}
    >
      <div
        className='FloorDetails-content'
        onClick={(e) => e.stopPropagation()}
      >
        <div className='header-container-new'>
          <div className='header'>
            <img
              alt=''
              src={close}
              onClick={() => {
                setIsSaveOpen(true);
              }}
            />
            Reservation Details
          </div>
          <div className='button-container'>
            <Button
              auth
              text='Save'
              height='40px'
              padding='0.75rem 2rem'
              onClick={() => handleSave("table")}
              loading={isLoading}
              disable={isTableError}
            />
          </div>
        </div>
        <div className='profile-details'>
          <div className='profile'>
            <div className='avatar'>
              {tableData.CustomerDetails.FirstName.charAt(0)}
              {/* <div className='edit'>
                <img alt='' src={edit} />
              </div> */}
            </div>
            <div className='name-conatiner'>
              <div className='name'>
                {" "}
                {tableData.CustomerDetails.FirstName}{" "}
                {tableData.CustomerDetails.LastName}
              </div>
              <div className='email'>
                <img alt='' src={email} />
                <td> {tableData.CustomerDetails.Email}</td>
              </div>
            </div>
          </div>
          <div className='more'>
            <img alt='' src={more} />
          </div>
        </div>
        <div className='tab-bar'>
          <div
            className={`item ${tab === "DETAILS" ? "selected" : ""} `}
            onClick={() => setTab("DETAILS")}
          >
            Details
          </div>
          <div
            className={`item ${tab === "PROFILE" ? "selected" : ""} `}
            onClick={() => setTab("PROFILE")}
          >
            Profile
          </div>
        </div>
        <div className='container'>
          <div className='left-container'>{getLeftContent()}</div>
          <div className='right-container'>{getRightContent()}</div>
        </div>
      </div>
      {isDatOpen && (
        <DateModal
          date={date}
          setDate={setDate}
          setDateOpen={setDateOpen}
          isOpen={isDatOpen}
          onClose={() => {
            setDateOpen(false);
          }}
          isEdit={true}
        />
      )}
      {isStatusOpen && (
        <StatusModal
          isOpen={isStatusOpen}
          onClose={() => {
            setStatusOpen(false);
          }}
          setStatus={setStatusValue}
          isEdit={true}
        />
      )}
      {isDurationOpen && (
        <DurationModal
          isOpen={isDurationOpen}
          onClose={() => {
            setDurationOpen(false);
          }}
          interval={interval}
          setInterval={setInterval}
          isEdit={true}
        />
      )}
      {isCoversOpen && (
        <CoverModal
          isOpen={isCoversOpen}
          onClose={() => {
            setCoversOpen(false);
          }}
          cover={guest}
          setCover={setGuest}
          isEdit={true}
        />
      )}
      {isTimeOpen && (
        <TimeModal
          time={bookingTime}
          setTime={setBookingTime}
          isOpen={isTimeOpen}
          onClose={() => {
            setTimeOpen(false);
          }}
          isEdit={true}
          date={date}
        />
      )}
      {/* {isRequestOpen && (
        <NotesModal
          notes={request}
          setNotes={setRequest}
          isOpen={true}
          onClose={() => {
            setRequestOpen(false);
          }}
          isEdit={true}
        />
      )}
      {isTagOpen && (
        <TagModal
          isOpen={isTagOpen}
          onClose={() => {
            setTagOpen(false);
          }}
          setTags={setTagsData}
          tags={tags}
          isEdit={true}
        />
      )} */}

      <SaveModal
        isOpen={isSaveOpen}
        onClose={() => {
          dispatch(setAllocatedTables([]));
          onClose();
        }}
        onOk={() => handleSave("save")}
        isLoading={isSaveLoading}
        disable={isTableError}
      />
    </div>
  );
};

export default FloorDetails;
