import React from "react";
import "./BookingModalStyles.scss";
import table from "../../../../assets/tName.svg";
import ppl from "../../../../assets/people.svg";
import time from "../../../../assets/alarm.svg";
import arrow from "../../../../assets/arrow.png";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../../application/store";
import tableIm from "../../../../assets/Table.svg";
import pplIm from "../../../../assets/ppl.svg";
import Button from "../../../button/Button";
import { setAllocatedTables } from "../../../../../application/reducer/bookingSlice";
import { useNavigate } from "react-router-dom";
import close from "../../../../assets/close.png";

interface BookingModalProps {
  isOpen: boolean;
  onClose: () => void;
  selected: any;
  setSelectedTableDetails: React.Dispatch<React.SetStateAction<any>>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const BookingModal: React.FC<BookingModalProps> = ({
  isOpen,
  onClose,
  selected,
  setSelectedTableDetails,
  setIsModalOpen,
}) => {
  const dispatch = useDispatch();
  const allocatedTables = useSelector(
    (state: RootState) => state.booking.allocatedTables
  );
  const navigate = useNavigate();

  if (!isOpen) {
    return null;
  }

  return (
    <div className='booking-modal-overlay' onClick={onClose}>
      <div
        className='booking-modal-content'
        onClick={(e) => e.stopPropagation()}
      >
        <div className='table-name'>
          <div className='left'>
            <div className='image'>
              <img alt='' src={table} />
            </div>
            {selected?.TableName}
          </div>
          <div className='right'>
            <img alt='' src={close} onClick={onClose} />
          </div>
        </div>
        <div className='covers-name'>
          <div className='head'>
            <div className='image'>
              <img alt='' src={ppl} />
            </div>
            Covers
          </div>
          <div className='cvr-cont'>
            <div className='cont'>
              <div className='cont-1'>Min</div>
              <div className='cont-2'>{selected?.Covers.Minimum}</div>
            </div>
            <div className='cont'>
              <div className='cont-1'>Max</div>
              <div className='cont-2'>{selected?.Covers.Maximum}</div>
            </div>
          </div>
        </div>
        <div className='time-name'>
          <div className='head'>
            <div className='image'>
              <img alt='' src={time} />
            </div>
            Time
            <div className='no-res'>Reservations</div>
          </div>

          <div className='content'>
            {selected?.Reservations.length > 0 ? (
              selected?.Reservations.map((item: any, index: number) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      onClose();
                      setSelectedTableDetails(item);
                      setIsModalOpen(true);
                    }}
                    className='item-cover'
                  >
                    <div className='left'>
                      <div className='text'>
                        {item.BookingDetails.BookingTime}
                      </div>
                      <div className='booking-details'>
                        <div className='det'>
                          <div className='table'>
                            <div className='tbl'>
                              <img alt='' src={tableIm} />
                            </div>
                            {item.AssetAllocation.length}
                          </div>
                          <div className='cover'>
                            <div className='cvr'>
                              <img alt='' src={pplIm} />
                            </div>
                            {item.BookingDetails.NumberOfPeople}
                          </div>
                        </div>
                        <div className='id-det'>#{item.ConfirmationNumber}</div>
                      </div>
                    </div>
                    <div className='right'>
                      <div
                        className='status'
                        style={{
                          color: item?.StatusInfo.Color,
                          backgroundColor: `${item?.StatusInfo.Color}12`,
                          border: `1px solid ${item?.StatusInfo.Color}1A`,
                        }}
                      >
                        <img alt='' src={item.StatusInfo.Icon} />
                      </div>
                      <img alt='' src={arrow} />
                    </div>
                  </div>
                );
              })
            ) : (
              <div className='no-booking'>No bookings available</div>
            )}
          </div>
          <div className='btn-container'>
            <Button
              text='CREATE RESERVATION'
              auth
              onClick={() => {
                let obj = {
                  Capacity: {
                    Max: selected.Covers.Maximum,
                    Min: selected.Covers.Minimum,
                  },
                  LocalReference: {
                    Floor: {
                      Id: selected.FloorID,
                    },
                    Table: {
                      Id: selected.LocalID,
                    },
                  },
                  Name: selected.TableName,
                  RefNo: selected.ID,
                  Status: "CONFIRMED",
                  Type: "TABLE",
                };
                dispatch(setAllocatedTables([...allocatedTables, obj]));
                navigate("/v1/booking/new-reservation");
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingModal;
