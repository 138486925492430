import React, { useEffect, useState } from "react";
import home from "../../../assets/fav.png";
import link1 from "../../../assets/link1.svg";
import link2 from "../../../assets/link2.svg";
import link3 from "../../../assets/list-gray.svg";
import grade from "../../../assets/list.svg";
import shift from "../../../assets/shift.svg";
import shiftPr from "../../../assets/shift-pr.svg";
import grouping from "../../../assets/av-gray.svg";
import groupingPr from "../../../assets/av.svg";

import link6 from "../../../assets/link6.svg";
import arr from "../../../assets/small-right.svg";
import arrow from "../../../assets/slide-left.svg";

import add from "../../../assets/add.svg";

import "./DrawerStyles.scss";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../application/store";
import { getFloorManagementDetails } from "../../../../infra/apis/bookings/requests/Booking";
import { setFloorLayoutData } from "../../../../application/reducer/bookingSlice";
const ICONS = [
  // {
  //   img: link1,
  //   header: "Calender",
  //   type: "CALENDER",
  // },
  // {
  //   img: link2,
  //   header: "Table Reservation",
  //   type: "TABLE_RESERVATION",
  // },
  {
    img: link3,
    header: "View",
    type: "VIEW",
    subTypes: ["FLOOR", "LIST"],
    selectedImg: grade,
  },
  // {
  //   img: grouping,
  //   header: "Availability",
  //   type: "AVAILABILITY",
  //   subTypes: ["TABLE GROUPING", "SHIFT MANAGEMENT"],
  //   selectedImg: groupingPr,
  // },
  // {
  //   img: link5,
  //   header: "Reservation Details",
  //   type: "RESERVATION_DETAILS",
  // },
  // {
  //   img: link6,
  //   header: "Pricing",
  //   type: "PRICING",
  // },
  // {
  //   img: link7,
  //   header: "Settings",
  //   type: "SETTINGS",
  // },
];
interface DrawerProps {
  isDrawerOpen: boolean;
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedMenu: string;
  setIsWarningOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}
const Drawer: React.FC<DrawerProps> = ({
  isDrawerOpen,
  setIsDrawerOpen,
  selectedMenu,
  setIsWarningOpen,
}) => {
  const floorLayoutData = useSelector(
    (state: RootState) => state.booking.floorLayoutData
  );
  const getMenu = () => {
    let menu = ICONS.find((icon: any) => icon.subTypes.includes(selectedMenu));
    return menu?.type;
  };
  let openMenu = {
    type: getMenu(),
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState<any>(openMenu);
  useEffect(() => {
    getFloorManagementDetails().then((response: any) => {
      if (response.length === 0) {
        dispatch(setFloorLayoutData([]));
      } else {
        dispatch(setFloorLayoutData(response));
      }
    });
  }, []);
  const handleOnclick = (value: string) => {
    switch (value) {
      case "FLOOR":
        navigate("/v1/booking/floor-view");
        break;
      case "LIST":
        navigate("/v1/booking/list-view");
        break;
      case "ADD":
        navigate("/v1/booking/new-reservation");
        break;
      case "TABLE GROUPING":
        navigate("/v1/booking/grouping");
        break;
      case "SHIFT MANAGEMENT":
        navigate("/v1/booking/shift-management");
        break;
      default:
        navigate("/v1/booking/list-view");
        break;
    }
  };
  const handleEmpty = () => {
    if (setIsWarningOpen) {
      navigate("/v1/booking/floor-layout");
      setIsWarningOpen(true);
    }
  };
  return (
    <div className='Drawer'>
      <div className='homeIC'>
        <div
          onClick={() => {
            // if (floorLayoutData.length > 0) {
            handleOnclick("LIST");
            // } else {
            //   handleEmpty();
            // }
          }}
          className='home-cont'
        >
          <img alt='' src={home} className='home' /> Reserve{" "}
        </div>
        <img
          alt=''
          src={arrow}
          onClick={() => setIsDrawerOpen(!isDrawerOpen)}
          className='arrow'
        />
      </div>
      <div
        className={`add-cont ${selectedMenu === "ADD" ? "add-Cont-sel" : ""}`}
        onClick={() => {
          // if (floorLayoutData.length > 0) {
          handleOnclick("ADD");
          // } else {
          //   handleEmpty();
          // }
        }}
      >
        <div className='addBtn'>
          <img alt='' src={add} />
        </div>
        Add Reservation
      </div>
      {ICONS.map((icon, index) => {
        return (
          <div className='list'>
            <div
              className={
                icon.subTypes.length > 0
                  ? icon.subTypes.includes(selectedMenu) &&
                    icon.type === getMenu()
                    ? "iconListSelected"
                    : "iconList"
                  : icon.type === selectedMenu
                  ? "iconListSelected"
                  : "iconList"
              }
              key={index}
              onClick={() => {
                let open = {
                  type: isOpen.type === icon.type ? "" : icon.type,
                };
                setIsOpen(open);
                if (icon.subTypes.length === 0) {
                  // if (floorLayoutData.length > 0) {
                  handleOnclick(icon.type);
                  // } else {
                  //   handleEmpty();
                  // }
                }
              }}
            >
              <div className='right'>
                <img
                  alt=''
                  src={
                    icon.subTypes.length > 0
                      ? icon.subTypes.includes(selectedMenu) &&
                        icon.type === getMenu()
                        ? icon.selectedImg
                        : icon.img
                      : icon.type === selectedMenu
                      ? icon.selectedImg
                      : icon.img
                  }
                />
                <div> {icon.header}</div>
              </div>
              <img
                alt=''
                src={arr}
                className={isOpen.type === icon.type ? "arr-rv" : "arr "}
              />
            </div>
            {isOpen.type === icon.type
              ? icon.subTypes?.map((type, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        selectedMenu === type ? "subtype-selected " : "subtype"
                      }
                      onClick={() => {
                        let open = {
                          type: icon.type,
                        };
                        setIsOpen(open);
                        // if (floorLayoutData.length > 0) {
                        handleOnclick(type);
                        // } else {
                        //   handleEmpty();
                        // }
                      }}
                    >
                      {type.toLowerCase()}
                    </div>
                  );
                })
              : ""}
            <hr className='border' />
          </div>
        );
      })}

      <div
        style={{ height: "100%" }}
        onClick={() => setIsDrawerOpen(!isDrawerOpen)}
      />
    </div>
  );
};
export default Drawer;
