import React, { useEffect, useState, useRef } from "react";
import "./FloorViewStyles.scss";
import FloorLayout from "../../../../components/ReservationComponents/floorLayout/FloorLayout";
import { getFloorDetails } from "../../../../../infra/apis/bookings/requests/Booking";
import { useDispatch, useSelector } from "react-redux";
import {
  setFloorData,
  setSelectedFloorData,
} from "../../../../../application/reducer/bookingSlice";
import { RootState } from "../../../../../application/store";
import BookingModal from "../../../../components/ReservationComponents/modal/bookingModal/BookingModal";
import FloorDetails from "../floorDetails/FloorDetails";
import TabelDetails from "../tableDetails/TableDetails";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import menu from "../../../../assets/menu.svg";
import SuccessModal from "../../../../components/ReservationComponents/errorModal/SuccessModal";

interface FloorViewProps {
  date: string;
  isView?: boolean;
  time: string;
  isDrawerOpen?: boolean;
}

const FloorView: React.FC<FloorViewProps> = ({
  date,
  isView,
  time,
  isDrawerOpen,
}) => {
  const dispatch = useDispatch();
  const floorData = useSelector((state: RootState) => state.booking.floorData);
  const [selectedFloor, setSelectedFloor] = useState<any>(null);
  const [selectedTable, setSelectedTable] = useState<any>(null);
  const [selectedTableDetails, setSelectedTableDetails] = useState<any>(null);
  const [selectedTables, setSelectedTables] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [bookingTime, setBookingTime] = useState<any>(
    selectedTable
      ? selectedTable.BookingDetails?.BookingTime
      : moment(new Date()).format("HH:mm")
  );
  const [dateVal, setDate] = useState<any>(
    selectedTable
      ? new Date(selectedTable?.BookingDetails?.BookingDate)
      : new Date()
  );
  const [guest, setGuest] = useState<number>(
    selectedTable ? selectedTable?.BookingDetails?.NumberOfPeople : 0
  );
  const parentRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [parentHeight, setParentHeight] = useState(540);
  const [parentWidth, setParentWidth] = useState(1000);

  const [isOpen, setIsOpen] = useState(false);
  const [isUpdateSuccess, setIsUpdateSuccess] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    getFloorDetails(date).then((response: any) => {
      const sortedFloor: any = [...response].sort(
        (a, b) => a.Priority - b.Priority
      );

      setSelectedFloor(sortedFloor[0]);
      dispatch(setSelectedFloorData(sortedFloor[0]));
      dispatch(setFloorData(sortedFloor));
    });
  }, [date]);
  useEffect(() => {
    const parent = parentRef.current;
    if (selectedFloor?.Tables?.length > 0) {
      if (parent) {
        const isOverflowingHeight = parent.scrollHeight > parent.clientHeight;
        if (isOverflowingHeight) {
          setParentHeight((prevHeight) => prevHeight + 540);
        }
      }
    } else {
      setParentHeight(540);
    }
  }, [selectedFloor, parentHeight]);
  useEffect(() => {
    const parent = parentRef.current;
    if (selectedFloor?.Tables?.length > 0) {
      if (parent) {
        const isOverflowingHeight = parent.scrollWidth > parent.clientWidth;
        if (isOverflowingHeight) {
          setParentWidth((prevWidth) => prevWidth + 500);
        }
      }
    } else {
      setParentWidth(1000);
    }
  }, [selectedFloor, parentWidth]);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  return (
    <div className='FloorView'>
      <div className='header-container'>
        <div
          className='rooms'
          style={{ width: isDrawerOpen && isDrawerOpen ? "46.5%" : "" }}
        >
          {floorData.map((floor: any) => {
            return (
              <div
                className={`content ${
                  selectedFloor?.FloorID === floor.FloorID
                    ? "content-selected "
                    : ""
                }`}
                onClick={() => {
                  setSelectedFloor(floor);
                  dispatch(setSelectedFloorData(floor));
                }}
              >
                {floor.DisplayName}
              </div>
            );
          })}
        </div>
        {/* {isView && (
          <div
            className='table-config'
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            Floor Configuration
            <img alt='' src={menu} className='menu' />
            {isOpen && (
              <div className='dropdown' ref={dropdownRef}>
                <div
                  className='item'
                  onClick={() => {
                    navigate("/v1/booking/floor-layout");
                  }}
                >
                  Edit Layout
                </div>
              </div>
            )}
          </div>
        )} */}
      </div>

      <div
        className='floor'
        ref={parentRef}
        style={{ height: `${parentHeight}px`, width: `${parentWidth}px` }}
      >
        <FloorLayout
          items={
            selectedFloor
              ? selectedFloor?.Tables !== null
                ? selectedFloor?.Tables
                : []
              : []
          }
          setSelected={setSelectedTable}
          isView={isView}
          time={time}
          date={date}
        />
      </div>

      <BookingModal
        isOpen={selectedTable !== null ? true : false}
        onClose={() => {
          setSelectedTable(null);
        }}
        selected={selectedTable}
        setSelectedTableDetails={setSelectedTableDetails}
        setIsModalOpen={setIsModalOpen}
      />
      <div style={{ position: "relative" }}>
        {isModalOpen && (
          <FloorDetails
            isModalOpen={isModalOpen}
            onClose={() => {
              setIsModalOpen(false);
            }}
            tableData={selectedTableDetails}
            setSelectedTables={setSelectedTables}
            setIsModalOpen={setIsModalOpen}
            date={dateVal}
            setDate={setDate}
            bookingTime={bookingTime}
            setBookingTime={setBookingTime}
            setGuest={setGuest}
            guest={guest}
            setSelectedTable={setSelectedTableDetails}
            setIsUpdateSuccess={setIsUpdateSuccess}
          />
        )}
        {selectedTables && (
          <TabelDetails
            isModalOpen={selectedTables}
            onClose={() => {
              setSelectedTables(false);
              setIsModalOpen(true);
            }}
            tableData={selectedTableDetails}
            setSelectedTable={setSelectedTableDetails}
            date={dateVal}
            bookingTime={bookingTime}
            guest={guest}
          />
        )}
        <SuccessModal
          isOpen={isUpdateSuccess}
          onClose={() => {
            window.location.reload();

            setIsUpdateSuccess(false);
          }}
          onOk={() => {
            setIsUpdateSuccess(false);
            window.location.reload();
          }}
          header='Successfully Updated!'
          content='Successfully, reservation details are updated.'
        />
      </div>
    </div>
  );
};
export default FloorView;
