import React, { useEffect, useState } from "react";
import "./BookingStyles.scss";
import LogoContainer from "../../components/logocontainer/LogoContainer";
import Button from "../../components/button/Button";
import BaseHeader from "../../components/baseheader/BaseHeader";
import TimeSlot from "../../components/timeslot/TimeSlot";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../application/store";
import { useParams } from "react-router-dom";
import {
  setAvailableDaySpots,
  setAvailableSpots,
  setDate,
  setGuest,
  setOrganizationalDetails,
  setShiftDetails,
  setTableDuration,
  setTime,
  setEmail,
  setFirstName,
  setLastName,
  setPhoneNumber,
  setRequest,
} from "../../../application/reducer/bookingSlice";
import {
  convertTime,
  getAllTimeSlotsForDate,
  getReservationCovers,
  getTimeSlotsForDate,
} from "../../../application/utils";
import {
  getOrganizationDetails,
  getShiftDetails,
  getSpotAvailability,
} from "../../../infra/apis/bookings/requests/Booking";
import moment from "moment";

const BookingPageOne: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSlotsLoading, setIsSlotsLoading] = useState(false);

  const dispatch = useDispatch();
  const guest = useSelector((state: RootState) => state.booking.guest);
  const date = useSelector((state: RootState) => state.booking.date);
  const time = useSelector((state: RootState) => state.booking.time);
  const shiftData = useSelector(
    (state: RootState) => state.booking.shiftDetails
  );
  const availableSpots = useSelector(
    (state: RootState) => state.booking.availableSpots
  );
  const availableDaySpots = useSelector(
    (state: RootState) => state.booking.availableDaySpots
  );
  const timeDaySlots = getTimeSlotsForDate(
    date,
    guest === 0 ? getReservationCovers(date, shiftData)[0] : guest,
    shiftData
  );
  const allDaySlots = getAllTimeSlotsForDate(date, shiftData);
  const organizationDetails = useSelector(
    (state: RootState) => state.booking.organizationDetails
  );
  const { locationId } = useParams<{
    locationId: string;
  }>();
  const openingHours = organizationDetails?.OpeningHours?.filter(
    (day: any) => !day.Closed
  ).map((day: any) => day.Day);
  const navigate = useNavigate();
  const findFirstBeforeSlot = (availableSpotsDetails: any) => {
    for (const option of timeDaySlots) {
      let slot = availableSpotsDetails.find(
        (slot: any) => convertTime(slot.StartTime) === option
      );

      if (slot !== undefined) {
        let startTime = new Date(slot.StartTime);
        let currentTime = new Date();
        let currentTimeUTC = new Date(
          startTime.getUTCFullYear(),
          startTime.getUTCMonth(),
          startTime.getUTCDate(),
          startTime.getUTCHours(),
          startTime.getUTCMinutes(),
          startTime.getUTCSeconds()
        );
        if (slot.Available) {
          let isBefore = currentTimeUTC < currentTime;
          if (!isBefore) {
            return option;
          }
        }
      }
    }
    return "";
  };
  const hanldleTimeSlots = (availableTimeSlots: any) => {
    if (!openingHours.includes(moment(date).format("dddd"))) {
      dispatch(setTime(""));
    } else {
      if (availableTimeSlots.length > 0) {
        if (findFirstBeforeSlot(availableTimeSlots) !== "") {
          dispatch(setTime(findFirstBeforeSlot(availableTimeSlots)));
        } else {
          dispatch(setTime(""));
        }
      } else {
        dispatch(setTime(""));
      }
    }
  };
  useEffect(() => {
    localStorage.setItem("wrlId", locationId || "");
    getUpdateData();
    dispatch(setFirstName(""));
    dispatch(setLastName(""));
    dispatch(setEmail(""));
    dispatch(setPhoneNumber(""));
    dispatch(setRequest(""));
  }, []);
  useEffect(() => {
    setIsSlotsLoading(true);
    let dateValue = moment(date).format("YYYY-MM-DD");
    getSpotAvailability(dateValue).then((response: any) => {
      dispatch(setAvailableDaySpots(response));
      const availableTimeSlots = response.filter(
        (spot: any) => spot.PartySize === guest && spot.SpotsOpen > 0
      );
      dispatch(setAvailableSpots(availableTimeSlots));
      hanldleTimeSlots(availableTimeSlots);
      setIsLoading(false);
      setIsSlotsLoading(false);
    });
  }, [date]);

  useEffect(() => {
    const availableTimeSlots = availableDaySpots.filter(
      (spot: any) => spot.PartySize === guest && spot.SpotsOpen > 0
    );
    dispatch(setAvailableSpots(availableTimeSlots));
    hanldleTimeSlots(availableTimeSlots);
  }, [guest]);
  const getUpdateData = async () => {
    await getOrganizationDetails(locationId || "").then(
      (orgnizationDetails) => {
        dispatch(setOrganizationalDetails(orgnizationDetails));
      }
    );
    await getShiftDetails(locationId || "").then((shiftData) => {
      console.log(shiftData, "shiftData");
      dispatch(setShiftDetails(shiftData));
      dispatch(setGuest(getReservationCovers(date, shiftData)[0]));
      const filteredDates = getDateOptions();
      dispatch(setDate(filteredDates[0]?.value));
    });
  };

  const getDateOptions = () => {
    const options = [];
    const today = new Date();

    for (let i = 0; i <= 14; i++) {
      const date = new Date();
      date.setDate(today.getDate() + i);

      let label = "";
      if (i === 0) {
        label = "Today";
      } else if (i === 1) {
        label = "Tomorrow";
      } else {
        label = date.toLocaleDateString("en-US", {
          weekday: "short",
          month: "short",
          day: "numeric",
        });
      }

      options.push({ value: date.toISOString().slice(0, 10), label });
    }
    return options;
  };
  const handleGuestChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(setGuest(parseInt(e.target.value, 10)));
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    // localStorage.setItem("bDate", e.target.value);
    dispatch(setDate(e.target.value));
  };
  const handleTimeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let slot = availableSpots.find(
      (slot: any) => convertTime(slot.StartTime) === e.target.value
    );
    let duration = slot.Duration / 60;
    dispatch(setTableDuration(duration));
    dispatch(setTime(e.target.value));
  };
  const handleClick = () => {
    if (guest > 0 && date !== "" && time !== "") {
      navigate("/booking/customer-details");
    }
  };
  return (
    <div className='page'>
      <div className='border' />
      {isLoading ? (
        <div className='loader' />
      ) : (
        <div className='BookingPageOne'>
          <div className='left-container'>
            {/* <div className='logo-witmeg'>
              <img alt='' src={witmeg} />
            </div> */}
            <LogoContainer />
          </div>
          <div className='right-container'>
            <div className='form-container'>
              <BaseHeader />
              <div className='guest-and-date-selector'>
                {openingHours.includes(moment(date).format("dddd")) && (
                  <div className='dropdown'>
                    <label htmlFor='guests'>
                      Party Size
                      <span style={{ color: "red", marginLeft: "0.2rem" }}>
                        *
                      </span>
                    </label>
                    <select
                      id='guests'
                      value={guest}
                      onChange={handleGuestChange}
                    >
                      {getReservationCovers(date, shiftData).map(
                        (value, index) => (
                          <option key={index} value={value}>
                            {value}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                )}

                <div className='dropdown'>
                  <label htmlFor='dates'>
                    Date
                    <span style={{ color: "red", marginLeft: "0.2rem" }}>
                      *
                    </span>
                  </label>
                  <select id='dates' value={date} onChange={handleDateChange}>
                    {getDateOptions().map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                        disabled={
                          !openingHours.includes(
                            moment(option.value).format("dddd")
                          )
                        }
                      >
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='dropdown'>
                  {openingHours.includes(moment(date).format("dddd")) &&
                    !isSlotsLoading && (
                      <>
                        <label htmlFor='dates'>
                          Time
                          <span style={{ color: "red", marginLeft: "0.2rem" }}>
                            *
                          </span>
                        </label>
                        <select
                          id='dates'
                          value={time}
                          onChange={handleTimeChange}
                        >
                          {allDaySlots.map((option: any, index) => {
                            let slot = availableSpots.find(
                              (slot: any) =>
                                convertTime(slot.StartTime) === option
                            );
                            let isAvailable = false;
                            let isBefore = false;
                            if (slot !== undefined) {
                              isAvailable = slot ? slot.Available : false;
                              let startTime = new Date(slot?.StartTime);
                              let currentTime = new Date();
                              let currentTimeUTC = new Date(
                                startTime.getUTCFullYear(),
                                startTime.getUTCMonth(),
                                startTime.getUTCDate(),
                                startTime.getUTCHours(),
                                startTime.getUTCMinutes(),
                                startTime.getUTCSeconds()
                              );
                              isBefore = currentTimeUTC < currentTime;
                            }

                            return (
                              <option
                                key={index}
                                value={time === "" ? "" : option}
                                disabled={
                                  !isAvailable ||
                                  isBefore ||
                                  !timeDaySlots.includes(option)
                                }
                                style={{
                                  backgroundColor:
                                    !isAvailable ||
                                    isBefore ||
                                    !timeDaySlots.includes(option)
                                      ? "#ededed"
                                      : "",
                                }}
                              >
                                {time === "" ? "" : option}
                              </option>
                            );
                          })}
                        </select>
                      </>
                    )}
                </div>
              </div>

              {openingHours.includes(moment(date).format("dddd")) ? (
                isSlotsLoading ? (
                  <div style={{ marginBottom: "15%", marginTop: "-5%" }}>
                    <div className='loader' />
                  </div>
                ) : (
                  <>
                    <div className='time-label'>
                      <label htmlFor='time'>
                        Select a time
                        <span style={{ color: "red", marginLeft: "0.2rem" }}>
                          *
                        </span>
                      </label>
                    </div>
                    <TimeSlot
                      timeSlots={timeDaySlots}
                      allDaySlots={allDaySlots}
                    />
                  </>
                )
              ) : (
                <div className='closed'>
                  <div>
                    <div className='header'>Closed</div>
                    <div className='sub'>Restaurant is closed for the day</div>
                  </div>
                </div>
              )}
              <div className='button-container'>
                <Button
                  text='continue'
                  onClick={handleClick}
                  disable={time === "" ? true : false}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default BookingPageOne;
