import React, { useState, useEffect, useRef } from "react";
import "./ReservationStyles.scss";
import SideBar from "../../../components/ReservationComponents/sideBar/SideBar";
import TopBar from "../../../components/ReservationComponents/topBar/TopBar";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../application/store";
import { useNavigate } from "react-router-dom";
import Drawer from "../../../components/ReservationComponents/drawer/Drawer";
import Button from "../../../components/button/Button";
import { getFloorManagementDetails } from "../../../../infra/apis/bookings/requests/Booking";
import {
  setFloorLayoutData,
  setSelectedFloorData,
} from "../../../../application/reducer/bookingSlice";
import FloorConfigLayout from "../../../components/ReservationComponents/floorLayout/FloorConfigLayout";
import { v4 as uuidv4 } from "uuid";
import DeleteModal from "../../../components/ReservationComponents/errorModal/DeleteModal";
import RenameModal from "../../../components/ReservationComponents/errorModal/RenameModal";
import TableLayout from "../../../components/ReservationComponents/floorLayout/Table/TableLayout";
import { useDrop } from "react-dnd";
import RoomLayout from "./RoomLayout";
import WarningModal from "../../../components/ReservationComponents/errorModal/WarningModal";
import table from "../../../assets/fl-table.svg";
import cvr from "../../../assets/fl-cvr.svg";
import onlinestatus from "../../../assets/fl-status.svg";
import {
  addFloor,
  deleteFloor,
  updateFloorName,
} from "../../../../infra/apis/bookings/requests/FloorLayout";

const Layout: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<any>(
    localStorage.getItem("selectedDate")
      ? JSON.parse(
          localStorage.getItem("selectedDate") || new Date().toDateString()
        )
      : new Date()
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [selectedFloor, setSelectedFloor] = useState<any>(null);
  const [parentHeight, setParentHeight] = useState(540);
  const [parentWidth, setParentWidth] = useState(1000);

  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [isRenameOpen, setIsRenameOpen] = useState<boolean>(false);
  const [renameLoading, setRenameLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [isWarningOpen, setIsWarningOpen] = useState<boolean>(false);

  const locationId = useSelector(
    (state: RootState) => state.booking.locationId
  );
  const floorLayoutData = useSelector(
    (state: RootState) => state.booking.floorLayoutData
  );
  const organizationDetails = useSelector(
    (state: RootState) => state.booking.organizationDetails
  );
  const parentRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [, ref] = useDrop({
    accept: "Room",
    drop: (draggedItem: any, monitor) => {
      const containerRect = containerRef?.current?.getBoundingClientRect();
      const offset = monitor.getClientOffset();
      if (!offset || !containerRect) return;
      let range = Math.ceil((offset.x - containerRect.left) / 150) - 1;
      const updatedItems = [...floorLayoutData];
      const draggedItemContent = updatedItems[draggedItem.index];
      updatedItems.splice(draggedItem.index, 1);
      updatedItems.splice(range, 0, draggedItemContent);
      setSelectedFloor(draggedItem.floor);
      dispatch(setFloorLayoutData(updatedItems));
    },
  });

  useEffect(() => {
    let userDetails = localStorage.getItem("userDetails")
      ? JSON.parse(localStorage.getItem("userDetails") || "")
      : "";
    if (userDetails === "" || userDetails === null) {
      navigate("/");
    } else {
      getData();
    }
  }, []);
  useEffect(() => {
    const parent = parentRef.current;
    if (selectedFloor?.Tables?.length > 0) {
      if (parent) {
        const isOverflowingHeight = parent.scrollHeight > parent.clientHeight;
        if (isOverflowingHeight) {
          setParentHeight((prevHeight) => prevHeight + 540);
        }
      }
    } else {
      setParentHeight(540);
    }
  }, [selectedFloor, parentHeight]);
  useEffect(() => {
    const parent = parentRef.current;
    if (selectedFloor?.Tables?.length > 0) {
      if (parent) {
        const isOverflowingHeight = parent.scrollWidth > parent.clientWidth;
        if (isOverflowingHeight) {
          setParentWidth((prevWidth) => prevWidth + 500);
        }
      }
    } else {
      setParentWidth(1000);
    }
  }, [selectedFloor, parentWidth]);
  useEffect(() => {
    getData();
  }, [selectedDate, locationId]);

  const getData = () => {
    setLoading(true);

    getFloorData();
  };
  const getFloorData = async () => {
    await getFloorManagementDetails().then((response: any) => {
      if (response.length === 0) {
        // createDummyRoom();
      } else {
        dispatch(setFloorLayoutData(response));
        setSelectedFloor(response[0]);
      }

      setLoading(false);
    });
  };
  const createDummyRoom = () => {
    let Tables = [
      {
        CloudLocationID: locationId,
        Covers: {
          Maximum: 4,
          Minimum: 1,
        },
        Dimentions: {
          Angle: 0,
          Height: 95,
          Shape: "RECTANGLE",
          TableName: "1",
          Width: 100,
          XValue: 0,
          YValue: 0,
        },
        IsOnline: true,
        OrganizationID: organizationDetails.ID,
        Priority: 1,
        TableName: "1",
        ID: uuidv4(),
      },
      {
        CloudLocationID: locationId,
        Covers: {
          Maximum: 4,
          Minimum: 1,
        },
        Dimentions: {
          Angle: 0,
          Height: 95,
          Shape: "CIRCLE",
          TableName: "1",
          Width: 100,
          XValue: 100,
          YValue: 0,
        },
        IsOnline: true,
        OrganizationID: organizationDetails.ID,
        Priority: 1,
        TableName: "2",
        ID: uuidv4(),
      },
    ];
    let room = {
      CloudLocationID: locationId,
      Description: "",
      DisplayName: `Main Room`,
      FloorID: uuidv4(),
      IsDeleted: false,
      Name: `Main Room`,
      OrganizationID: organizationDetails.ID,
      Priority: 1,
      Tables: Tables,
    };
    addFloor(room).then((response) => {
      console.log(response, "res");
    });
    let floor = [];
    floor.push(room);
    setSelectedFloor(floor[0]);
    dispatch(setFloorLayoutData(floor));
  };
  const addNewRoom = () => {
    let newRoom = {
      CloudLocationID: locationId,
      Description: "",
      DisplayName: `Main Room ${floorLayoutData.length + 1}`,
      FloorID: uuidv4(),
      IsDeleted: false,
      Name: `Main Room ${floorLayoutData.length + 1}`,
      OrganizationID: organizationDetails.ID,
      Priority: floorLayoutData.length + 1,
      Tables: [],
    };
    setSelectedFloor(newRoom);
    dispatch(setFloorLayoutData([...floorLayoutData, newRoom]));
    addFloor(newRoom).then((response) => {
      console.log(response, "res");
    });
  };
  const updateRoomName = () => {
    let newRoom = {
      ...selectedFloor,
      DisplayName: name,
      Name: name,
    };
    setRenameLoading(true);

    updateFloorName(newRoom)
      .then((response) => {
        setRenameLoading(false);
        setIsRenameOpen(false);
      })
      .catch(() => {
        setRenameLoading(false);
      });
  };

  const duplicateRoom = () => {
    let duplicateRoomObj = {
      CloudLocationID: locationId,
      Description: selectedFloor.Description,
      DisplayName: selectedFloor.DisplayName,
      FloorID: uuidv4(),
      IsDeleted: selectedFloor.IsDeleted,
      Name: selectedFloor.Name,
      OrganizationID: organizationDetails.ID,
      Priority: floorLayoutData.length + 1,
      Tables: selectedFloor.Tables,
    };
    setSelectedFloor(duplicateRoomObj);
    dispatch(setFloorLayoutData([...floorLayoutData, duplicateRoomObj]));
  };
  const countTableDetails = (): any => {
    return selectedFloor.Tables.reduce(
      (acc: any, table: any) => {
        acc.tableCount = selectedFloor.Tables.length;
        acc.totalMinCovers += table.Covers.Minimum;
        acc.totalMaxCovers += table.Covers.Maximum;
        if (table.IsOnline) {
          acc.onlineStatusCount += 1;
        }
        return acc;
      },
      {
        totalMinCovers: 0,
        totalMaxCovers: 0,
        onlineStatusCount: 0,
        tableCount: 0,
      }
    );
  };
  const deleteRoom = () => {
    setDeleteLoading(true);
    let id = selectedFloor && selectedFloor.FloorID;

    deleteFloor(id)
      .then((response) => {
        console.log(response, "response");
        setDeleteLoading(false);
        setIsDeleteOpen(false);
      })
      .catch(() => {
        setDeleteLoading(false);
      });
  };
  return (
    <div className='Reservation'>
      {window.screen.width > 900 ? (
        <>
          {isDrawerOpen ? (
            <Drawer
              isDrawerOpen={isDrawerOpen}
              setIsDrawerOpen={setIsDrawerOpen}
              selectedMenu={"FLOOR"}
              setIsWarningOpen={setIsWarningOpen}
            />
          ) : (
            <SideBar
              isDrawerOpen={isDrawerOpen}
              setIsDrawerOpen={setIsDrawerOpen}
              selected={"FLOOR"}
              setIsWarningOpen={setIsWarningOpen}
            />
          )}
          <div
            className='reservation-content'
            style={{ marginLeft: isDrawerOpen ? "16.25rem" : "5rem" }}
          >
            <TopBar
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              title='Layout'
              isOpenDrawer={isDrawerOpen}
              isBack={floorLayoutData.length > 0 ? true : false}
            />
            {loading ? (
              <div className='loader' />
            ) : (
              <div className='layout-view'>
                <div className='left-view'>
                  <div
                    className='fixed-view'
                    style={{ width: isDrawerOpen ? "19.9%" : "" }}
                  >
                    <div className='header-container'>
                      <div className='content content-selected '>Tables</div>
                      <div className='content'></div>
                    </div>
                  </div>
                  <div className='table-view'>
                    <div className='header'>Table Options</div>
                    <div className='sub'>Drag and drop your tables</div>
                    <div>
                      <div className='table-layout'>
                        <TableLayout
                          height={95}
                          width={100}
                          chairCount={2}
                          type='RECTANGLE'
                          name='1'
                          isLocked={false}
                          isView={true}
                          isEdit={false}
                          isNew={true}
                          maxCovers={4}
                        />
                        <TableLayout
                          height={95}
                          width={100}
                          chairCount={2}
                          type='CIRCLE'
                          name='2'
                          isLocked={false}
                          isView={true}
                          isEdit={false}
                          isNew={true}
                          maxCovers={4}
                        />
                      </div>
                      <div className='table-layout'>
                        <TableLayout
                          height={95}
                          width={190}
                          chairCount={4}
                          type='RECTANGLE'
                          name='2'
                          isLocked={false}
                          isView={true}
                          isEdit={false}
                          isNew={true}
                          maxCovers={6}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='right-view'>
                  <div className='FloorView'>
                    <div className='header-container'>
                      <div
                        className='rooms'
                        style={{ width: isDrawerOpen ? "46%" : "" }}
                        ref={(node) => {
                          ref(node);
                          if (containerRef) {
                            containerRef.current = node;
                          }
                        }}
                      >
                        {floorLayoutData.map((floor: any, index: number) => {
                          return (
                            <RoomLayout
                              floor={floor}
                              selectedFloor={selectedFloor}
                              setSelectedFloor={setSelectedFloor}
                              setName={setName}
                              setIsDeleteOpen={setIsDeleteOpen}
                              setIsRenameOpen={setIsRenameOpen}
                              duplicateRoom={duplicateRoom}
                              index={index}
                            />
                          );
                        })}
                      </div>
                      <div className='btn-container'>
                        <Button
                          text='  Add Room'
                          auth
                          height='34px'
                          width='120px'
                          padding='0'
                          onClick={() => {
                            if (floorLayoutData.length === 0) {
                              createDummyRoom();
                            } else {
                              addNewRoom();
                            }
                          }}
                          icon
                        />
                      </div>
                    </div>

                    <div
                      className='floor-l'
                      ref={parentRef}
                      style={{
                        height: `${parentHeight}px`,
                        width: `${parentWidth}px`,
                      }}
                    >
                      <FloorConfigLayout
                        items={
                          selectedFloor
                            ? selectedFloor?.Tables !== null
                              ? selectedFloor?.Tables
                              : []
                            : []
                        }
                        setSelectedFloor={setSelectedFloor}
                        selectedFloor={selectedFloor}
                        addRoom={createDummyRoom}
                      />
                    </div>
                  </div>
                  {floorLayoutData.length > 0 && (
                    <div className='floating'>
                      <div className='item'>
                        <img alt='' src={table} />
                        {selectedFloor && countTableDetails().tableCount} Tables
                      </div>
                      <div className='item'>
                        <img alt='' src={cvr} />
                        {selectedFloor &&
                          countTableDetails().totalMinCovers}{" "}
                        Min. Covers
                      </div>
                      <div className='item'>
                        <img alt='' src={cvr} />
                        {selectedFloor &&
                          countTableDetails().totalMaxCovers}{" "}
                        Max. Covers
                      </div>
                      <div className='item'>
                        <img alt='' src={onlinestatus} />
                        {selectedFloor &&
                          countTableDetails().totalMinCovers} -{" "}
                        {selectedFloor && countTableDetails().totalMaxCovers}{" "}
                        Online Capacity
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className='landscape'>
          Please rotate your device to landscape mode
        </div>
      )}
      <DeleteModal
        isOpen={isDeleteOpen}
        onClose={() => {
          setIsDeleteOpen(false);
        }}
        onDelete={() => {
          deleteRoom();
        }}
        content='Do you want to delete this room? '
        isLoading={deleteLoading}
      />
      <RenameModal
        isOpen={isRenameOpen}
        onClose={() => {
          setIsRenameOpen(false);
        }}
        onChange={() => {
          updateRoomName();
        }}
        isLoading={renameLoading}
        name={name}
        setName={setName}
      />
      <WarningModal
        isOpen={isWarningOpen}
        onClose={() => {
          setIsWarningOpen(false);
        }}
        onOk={() => {
          setIsWarningOpen(false);
        }}
        content='You have not added any tables yet. lets start by editing the floor layout'
        header='Warning'
      />
    </div>
  );
};
export default Layout;
