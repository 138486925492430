import React, { useEffect, useState } from "react";
import arrow from "../../assets/Shape.svg";
import check from "../../assets/checkmark.png";

interface GroupingDropdownProps {
  floor: any;
  selected: any;
  setSelected: React.Dispatch<React.SetStateAction<any>>;
}
const GroupingDropdown: React.FC<GroupingDropdownProps> = ({
  floor,
  selected,
  setSelected,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isValid = (asset: any) => {
    return selected.find((table: any) => table.ID === asset.ID).IsMergeable;
  };
  console.log(selected);
  return (
    <div>
      <div
        className={`floor-type ${isOpen ? "floor-type-selectd " : ""}`}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div className='left-shift-view'>{floor.DisplayName}</div>
        <div className='right-shift-view '>
          <div className='arrow-cont'>
            <img alt='' src={arrow} />
          </div>
        </div>
      </div>
      {isOpen ? (
        <div className='tables'>
          {floor.Tables.length > 0 ? (
            floor.Tables.map((asset: any) => {
              return (
                <div
                  className={`table ${isValid(asset) ? "table-selected " : ""}`}
                  onClick={() => {
                    const updatedTables = selected.map((item: any) => {
                      if (item.ID === asset.ID) {
                        return { ...item, IsMergeable: !item.IsMergeable };
                      }
                      return item;
                    });

                    setSelected(updatedTables);
                  }}
                >
                  <div>
                    {asset.TableName}{" "}
                    {`(${asset.Covers.Minimum},${asset.Covers.Maximum})`}
                  </div>
                  {isValid(asset) ? (
                    <div className='checked'>
                      <img alt='' src={check} />
                    </div>
                  ) : (
                    <div className='check' />
                  )}
                </div>
              );
            })
          ) : (
            <div className='table'>No tables available</div>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default GroupingDropdown;
