import React, { useState, useEffect } from "react";
import "./ReservationStyles.scss";
import SideBar from "../../../components/ReservationComponents/sideBar/SideBar";
import TopBar from "../../../components/ReservationComponents/topBar/TopBar";
import { useNavigate } from "react-router-dom";
import Drawer from "../../../components/ReservationComponents/drawer/Drawer";
import FloorView from "./floorView/FloorView";
import guestImg from "../../../assets/guest.svg";
import walk from "../../../assets/walking.svg";
import wait from "../../../assets/wait.svg";
import tick from "../../../assets/checkmark.png";
import InputComponent from "../../../components/inputcomponent/InputComponent";
import NumberInputComponent from "../../../components/inputcomponent/NumberInputComponent";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../application/store";
import Button from "../../../components/button/Button";
import NumberInput from "../../../components/ReservationComponents/numberInput/NumberInput";
import calendar from "../../../assets/clIC.png";
import timeImg from "../../../assets/alarm.svg";
import covers from "../../../assets/friends.png";
import duration from "../../../assets/duration.png";
import table from "../../../assets/cover.svg";
import status from "../../../assets/status.png";
import est from "../../../assets/milestone.png";
import notes from "../../../assets/notes.svg";
import back from "../../../assets/arrow.png";
import arrow from "../../../assets/grd-arr.svg";
import DurationInput from "../../../components/ReservationComponents/numberInput/DurationInput";
import "react-datepicker/dist/react-datepicker.css";
import StatusModal from "../../../components/ReservationComponents/modal/statusModal/StatusModal";
import CoverModal from "../../../components/ReservationComponents/modal/coverModal/CoverModal";
import DurationModal from "../../../components/ReservationComponents/modal/durationModal/DurationModal";
import moment from "moment";
import DateModal from "../../../components/ReservationComponents/modal/dateModal/DateModal";
import CommentModal from "../../../components/ReservationComponents/modal/commentsModal/CommentsModal";
import NotesModal from "../../../components/ReservationComponents/modal/noteModal/NotesModal";
import TimeModal from "../../../components/ReservationComponents/modal/timeModal/TimeModal";
import TagModal from "../../../components/ReservationComponents/modal/tagModal/TagModal";
import EstModal from "../../../components/ReservationComponents/modal/estModal/EstModal";
import TimeInput from "../../../components/ReservationComponents/numberInput/TimeInput";
import Alert from "./tableDetails/Alert";
import {
  makeReservationInhouse,
  getStatus,
  getTags,
} from "../../../../infra/apis/bookings/requests/Booking";
import {
  setAllocatedTables,
  setBookingStatus,
  setBookingTags,
} from "../../../../application/reducer/bookingSlice";
import SuccessModal from "../../../components/ReservationComponents/errorModal/SuccessModal";
import TableErrorModal from "../../../components/ReservationComponents/errorModal/TableErrorModal";

const NewReservation: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<any>(new Date());
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    rules: false,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [waitCheck, setWaitCheck] = useState<boolean>(false);
  const [walkCheck, setWalkCheck] = useState<boolean>(false);
  const [interval, setInterval] = useState<number>(120);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [guest, setGuest] = useState<number>(1);
  const [date, setDate] = useState<any>(new Date());
  const [bookingTime, setBookingTime] = useState<any>(
    moment(new Date()).format("HH:mm")
  );
  const [time, setTime] = useState<string>("0 min");
  const [request, setRequest] = useState<string>("");
  const [comments, setComments] = useState<string>("");
  const [statusValue, setStatusValue] = useState<any>("");
  const [tags, setTagsData] = useState<any[]>([]);
  const [isRequestOpen, setRequestOpen] = useState<boolean>(false);
  const [isCommentsOpen, setCommentsOpen] = useState<boolean>(false);
  const [isStatusOpen, setStatusOpen] = useState<boolean>(false);
  const [isCoversOpen, setCoversOpen] = useState<boolean>(false);
  const [isDurationOpen, setDurationOpen] = useState<boolean>(false);
  const [isDatOpen, setDateOpen] = useState<boolean>(false);
  const [isTimeOpen, setTimeOpen] = useState<boolean>(false);
  const [isTagOpen, setTagOpen] = useState<boolean>(false);
  const [isWaitOpen, setWaitOpen] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isTableError, setIsTableError] = useState<boolean>(false);
  const [waitESt, setWaitsEst] = useState<string>("15 mins");
  const [isAlertVisible, setIsAlertVisible] = useState("");

  const bookingStatus = useSelector(
    (state: RootState) => state.booking.bookingStatus
  );
  const allocatedTables = useSelector(
    (state: RootState) => state.booking.allocatedTables
  );
  const selectedFloorData = useSelector(
    (state: RootState) => state.booking.selectedFloorData
  );
  const organizationDetails = useSelector(
    (state: RootState) => state.booking.organizationDetails
  );
  const openingHours = organizationDetails?.OpeningHours?.filter(
    (day: any) => !day.Closed
  ).map((day: any) => day.Day);
  const getStatusData = (name: string) => {
    return bookingStatus
      .flatMap((category) => category.Statuses)
      .find((status) => status.Name.toLowerCase() === name.toLowerCase());
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const roundToNextQuarterHour = (time: moment.Moment): moment.Moment => {
    const minutes = time.minutes();
    const nextQuarterHour = Math.ceil(minutes / 15) * 15;
    if (nextQuarterHour === 60) {
      return time.add(1, "hour").startOf("hour").add(15, "minutes");
    }
    return time.minutes(nextQuarterHour).seconds(0);
  };
  const handleRoundTime = (timeStr: string) => {
    const time = moment(timeStr, "HH:mm");
    const rounded = roundToNextQuarterHour(time);
    return rounded.format("HH:mm");
  };
  const convertTimeToMinutesString = (timeString: any) => {
    const [hours, minutes] = timeString.split(":").map(Number);
    return hours * 60 + minutes;
  };
  const isTimeMatchingReservationWithDuration = (
    time: string,
    reservation: any
  ): boolean => {
    const givenTimeInMinutes = convertTimeToMinutesString(time);

    const bookingTimeInMinutes = convertTimeToMinutesString(
      reservation?.BookingDetails?.BookingTime
    );
    const endTimeInMinutes =
      bookingTimeInMinutes + reservation?.BookingDetails?.Duration;
    return (
      givenTimeInMinutes >=
        bookingTimeInMinutes - reservation?.BookingDetails?.Duration &&
      givenTimeInMinutes < endTimeInMinutes
    );
  };
  const isTimeMatchingReservation = () => {
    let reservations = selectedFloorData?.Tables?.flatMap(
      (item: any) => item.Reservations
    );
    if (reservations && reservations.length > 0) {
      return reservations.some((reservation: any) => {
        return reservation.AssetAllocation?.some((res: any) => {
          return allocatedTables.some((table: any) => {
            let dateValue = new Date(reservation?.BookingDetails?.BookingDate);

            let resDate = new Date(
              dateValue.getUTCFullYear(),
              dateValue.getUTCMonth(),
              dateValue.getUTCDate(),
              dateValue.getUTCHours(),
              dateValue.getUTCMinutes(),
              dateValue.getUTCSeconds()
            );
            return (
              res.RefNo === table.RefNo &&
              moment(resDate).format("DD-MM-yyyy") ===
                moment(date).format("DD-MM-yyyy") &&
              isTimeMatchingReservationWithDuration(bookingTime, reservation)
            );
          });
        });
      });
    }
  };
  const getDayString = (day: number): string => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return daysOfWeek[day];
  };
  const getNextOpenDate = () => {
    let dayIndex = new Date().getDay();
    for (let i = 1; i <= 7; i++) {
      dayIndex = (dayIndex + 1) % 7;
      const dayString = getDayString(dayIndex);
      const nextOpeningHour = openingHours?.find(
        (day: any) => day === dayString
      );
      if (nextOpeningHour && !nextOpeningHour.Closed) {
        const nextOpenDate = new Date(date);
        nextOpenDate.setDate(date.getDate() + i);
        return nextOpenDate;
      } else {
        return new Date();
      }
    }
  };
  useEffect(() => {
    let userDetails = localStorage.getItem("userDetails")
      ? JSON.parse(localStorage.getItem("userDetails") || "")
      : "";
    if (userDetails === "" || userDetails === null) {
      navigate("/");
    }
    // setDate(getNextOpenDate());
  }, []);
  const getMaxCapacitySum = () => {
    let totalCapacity = 0;
    for (const table of allocatedTables) {
      totalCapacity += table.Capacity.Max;
    }
    return totalCapacity;
  };
  useEffect(() => {
    getStatus().then((response: any) => {
      dispatch(setBookingStatus(response));
      setStatusValue(getStatusData("Not_Confirmed"));
    });
    getTags().then((response: any) => {
      dispatch(setBookingTags(response));
    });
  }, []);
  useEffect(() => {
    if (!waitCheck && !walkCheck) {
      setStatusValue(getStatusData("Not_Confirmed"));
    }
    if (waitCheck) {
      setStatusValue(getStatusData("Wait_List"));
    }
    if (!waitCheck && walkCheck) {
      setStatusValue(getStatusData("Confirmed"));
    }
  }, [waitCheck, walkCheck]);
  useEffect(() => {
    if (allocatedTables.length === 0) {
      setIsAlertVisible("danger");
    } else if (getMaxCapacitySum() < guest && allocatedTables.length > 0) {
      setIsAlertVisible("warning");
    } else if (getMaxCapacitySum() / 2 > guest && allocatedTables.length > 0) {
      setIsAlertVisible("warning-2");
    } else {
      setIsAlertVisible("");
    }
  }, [guest, allocatedTables]);
  useEffect(() => {
    if (allocatedTables.length === 0) {
      setIsAlertVisible("danger");
    } else if (getMaxCapacitySum() < guest && allocatedTables.length > 0) {
      setIsAlertVisible("warning");
    } else if (getMaxCapacitySum() / 2 > guest && allocatedTables.length > 0) {
      setIsAlertVisible("warning-2");
    } else {
      setIsAlertVisible("");
    }
  }, [allocatedTables]);
  useEffect(() => {
    setIsTableError(isTimeMatchingReservation());
  }, [bookingTime, allocatedTables, date, selectedFloorData]);
  const validateEmail = (email: string): boolean => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };
  const validateNumber = (number: string) => {
    const pattern = /^\d{4} \d{6}$/;
    if (!pattern.test(number)) {
      return true;
    }
    return false;
  };
  const validateForm = (): boolean => {
    let valid = true;
    const newErrors = {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      rules: false,
    };
    const regex = /^[A-Za-z]*$/;
    if (firstName.trim() === "") {
      newErrors.firstName = "*required";
      valid = false;
    }
    if (lastName.trim() === "") {
      newErrors.lastName = "*required";
      valid = false;
    }
    if (phoneNumber.trim() === "") {
      newErrors.phoneNumber = "*required";
      valid = false;
    }
    if (validateNumber(phoneNumber)) {
      newErrors.phoneNumber = "*Invalid phone number";
      valid = false;
    }
    if (email.trim() === "") {
      newErrors.email = "*required";
      valid = false;
    } else if (!validateEmail(email)) {
      newErrors.email = "*Invalid email format";
      valid = false;
    }
    if (!regex.test(firstName.trim())) {
      newErrors.firstName = "*Only alphabetic characters are allowed.";
      valid = false;
    }
    if (!regex.test(lastName.trim())) {
      newErrors.lastName = "*Only alphabetic characters are allowed.";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };
  const handleChange = (name: any) => {
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const detail = [
    {
      head: "Date",
      sub: <div>{moment(date).format("ddd, MMMM DD")}</div>,
      icon: calendar,
      hide: false,
      isOpen: isDatOpen,
    },
    {
      head: "Time",
      sub: (
        <TimeInput
          value={handleRoundTime(bookingTime)}
          setValue={setBookingTime}
          setTimeOpen={() => {
            setCommentsOpen(false);
            setStatusOpen(false);
            setDateOpen(false);
            setTimeOpen(!isTimeOpen);
            setTagOpen(false);
            setWaitOpen(false);
            setCoversOpen(false);
            setDurationOpen(false);
            setRequestOpen(false);
          }}
          newReservation
        />
      ),
      icon: timeImg,
      hide: false,
      isOpen: isTimeOpen,
    },
    {
      head: "Party Size",
      sub: (
        <NumberInput
          value={guest}
          setValue={setGuest}
          setCoversOpen={() => {
            setCommentsOpen(false);
            setStatusOpen(false);
            setDateOpen(false);
            setTimeOpen(false);
            setTagOpen(false);
            setWaitOpen(false);
            setCoversOpen(!isCoversOpen);
            setDurationOpen(false);
            setRequestOpen(false);
          }}
          newReservation
        />
      ),
      icon: covers,
      hide: false,
      isOpen: isCoversOpen,
    },
    {
      head: "Duration",
      sub: (
        <DurationInput
          value={time}
          setValue={setTime}
          interval={interval}
          setDurationOpen={() => {
            setCommentsOpen(false);
            setStatusOpen(false);
            setDateOpen(false);
            setTimeOpen(false);
            setTagOpen(false);
            setWaitOpen(false);
            setCoversOpen(false);
            setDurationOpen(!isDurationOpen);
            setRequestOpen(false);
          }}
          newReservation
        />
      ),
      icon: duration,
      hide: false,
      isOpen: isDurationOpen,
    },
    {
      head: "Table",
      sub: (
        <div style={{ columnGap: "0.5rem", display: "flex" }}>
          {allocatedTables.map((table) => {
            return <div>{table.Name}</div>;
          })}
        </div>
      ),

      icon: table,
      hide: false,
    },
    {
      head: "Status",
      sub: (
        <div
          style={{
            color: statusValue?.Color,
            display: "flex",
            alignItems: "center",
            columnGap: "5px",
          }}
        >
          <img alt='' src={statusValue?.Image} style={{ height: "14px" }} />
          <img />
          {statusValue?.Name?.replace("_", " ")}
        </div>
      ),
      icon: status,
      hide: false,
      isOpen: isStatusOpen,
    },
    {
      head: "Wait Est.",
      sub: waitESt,
      icon: est,
      hide: !waitCheck,
      isOpen: isWaitOpen,
    },
    // {
    //   head: "Comments",
    //   sub: isCommentsOpen ? comments : comments === "" ? "None" : comments,
    //   icon: reserve,
    //   hide: false,
    //   isOpen: isCommentsOpen,
    // },
    // {
    //   head: "Tags",
    //   sub: <div>{tags.map((tag) => tag.Name)}</div>,
    //   icon: reserve,
    //   hide: false,
    //   isOpen: isTagOpen,
    // },
    {
      head: "Notes",
      sub: isRequestOpen ? request : request === "" ? "None" : request,
      icon: notes,
      hide: false,
      isOpen: isRequestOpen,
    },
  ];

  const handleItemOnClick = (item: string) => {
    switch (item) {
      case "Notes":
        setCommentsOpen(false);
        setStatusOpen(false);
        setDateOpen(false);
        setTimeOpen(false);
        setTagOpen(false);
        setWaitOpen(false);
        setCoversOpen(false);
        setDurationOpen(false);
        setRequestOpen(!isRequestOpen);
        break;
      case "Comments":
        setStatusOpen(false);
        setDateOpen(false);
        setTimeOpen(false);
        setTagOpen(false);
        setWaitOpen(false);
        setCoversOpen(false);
        setDurationOpen(false);
        setRequestOpen(false);
        setCommentsOpen(!isCommentsOpen);
        break;
      case "Status":
        setDateOpen(false);
        setTimeOpen(false);
        setTagOpen(false);
        setWaitOpen(false);
        setCoversOpen(false);
        setDurationOpen(false);
        setRequestOpen(false);
        setCommentsOpen(false);
        setStatusOpen(!isStatusOpen);
        break;
      case "Date":
        setTimeOpen(false);
        setTagOpen(false);
        setWaitOpen(false);
        setCoversOpen(false);
        setDurationOpen(false);
        setRequestOpen(false);
        setCommentsOpen(false);
        setStatusOpen(false);
        setDateOpen(!isDatOpen);
        break;
      case "Time":
        setTagOpen(false);
        setWaitOpen(false);
        setCoversOpen(false);
        setDurationOpen(false);
        setRequestOpen(false);
        setCommentsOpen(false);
        setStatusOpen(false);
        setDateOpen(false);
        setTimeOpen(!isTimeOpen);
        break;
      case "Tags":
        setWaitOpen(false);
        setCoversOpen(false);
        setDurationOpen(false);
        setRequestOpen(false);
        setCommentsOpen(false);
        setStatusOpen(false);
        setDateOpen(false);
        setTimeOpen(false);
        setTagOpen(!isTagOpen);
        break;
      case "Wait Est.":
        setCoversOpen(false);
        setDurationOpen(false);
        setRequestOpen(false);
        setCommentsOpen(false);
        setStatusOpen(false);
        setDateOpen(false);
        setTimeOpen(false);
        setTagOpen(false);
        setWaitOpen(!isWaitOpen);
        break;
      default:
        break;
    }
  };
  const filteredDetail = detail.filter((item) => {
    return !item.hide;
  });

  const getAlert = () => {
    if (isAlertVisible === "danger") {
      return <Alert type='danger' content='You need to select a table.' />;
    } else if (isAlertVisible === "warning") {
      return <Alert type='warning' content={`Tables are over utilized.`} />;
    } else if (isAlertVisible === "warning-2") {
      return (
        <Alert
          type='warning'
          content={`Tables are underutilized. Maximum capacity is ${getMaxCapacitySum()}`}
        />
      );
    }
  };
  const convertTimeToMinutes = (timeString: string) => {
    let totalMinutes = 0;

    // Use a regular expression to extract the numbers and their units
    const timeParts = timeString.match(/(\d+)\s*(h|min)/g);

    if (timeParts) {
      timeParts.forEach((part) => {
        if (part.includes("h")) {
          const hours = parseInt(part.replace("h", ""), 10);
          totalMinutes += hours * 60;
        } else if (part.includes("min")) {
          const minutes = parseInt(part.replace("min", ""), 10);
          totalMinutes += minutes;
        }
      });
    }

    return totalMinutes;
  };
  const transformArray = () => {
    return tags.map((item) => ({
      Id: item.TagID,
      Name: item.Name,
      Icon: item.Image,
    }));
  };
  const handleSubmit = () => {
    if (!isLoading) {
      if (
        validateForm() &&
        allocatedTables.length > 0 &&
        (isAlertVisible === "" || isAlertVisible !== "danger")
      ) {
        let statusObj = {
          Id: statusValue.StatusID,
          Icon: statusValue.Image,
          DiplayName: statusValue.DisplayName,
          Color: statusValue.Color,
          Category: {
            Id: statusValue.CategoryID,
            Name: "",
          },
        };
        let reserve = {
          firstName: firstName,
          lastName: lastName,
          phoneNumber: phoneNumber
            .replace(" ", "")
            .replace(/(\d{2})(\d{4})(\d{4})/, "$1 $2 $3"),
          email: email,
          date: moment(date).format("YYYY-MM-DD"),
          guest: guest,
          time: handleRoundTime(bookingTime),
          request: request,
          duration: convertTimeToMinutes(time),
          assets: allocatedTables,
          status: statusValue.DisplayName,
          statusInfo: statusObj,
          waitList: waitCheck,
          waitTime: convertTimeToMinutes(waitESt),
          walkIn: walkCheck,
          tags: transformArray(),
        };
        setIsLoading(true);
        makeReservationInhouse(reserve)
          .then((reserveObj: any) => {
            if (reserveObj) {
              setIsLoading(false);
              clear();
              setIsSuccess(true);
            }
          })
          .catch(() => {
            setIsLoading(false);
          });
      }
    }
  };
  const clear = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");
    setGuest(1);
    setRequest("");
    setWaitCheck(false);
    setWalkCheck(false);
    setBookingTime(moment(new Date()).format("HH:mm"));
    setDate(new Date());
    setTime("15 mins");
    setStatusValue("");
    setTagsData([]);
    dispatch(setAllocatedTables([]));
    setIsAlertVisible("");
  };
  const closeTabs = () => {
    setCommentsOpen(false);
    setStatusOpen(false);
    setDateOpen(false);
    setTimeOpen(false);
    setTagOpen(false);
    setWaitOpen(false);
    setCoversOpen(false);
    setDurationOpen(false);
    setRequestOpen(false);
  };
  return (
    <div className='Reservation'>
      {window.screen.width > 900 ? (
        <>
          {isDrawerOpen ? (
            <Drawer
              isDrawerOpen={isDrawerOpen}
              setIsDrawerOpen={setIsDrawerOpen}
              selectedMenu={"ADD"}
            />
          ) : (
            <SideBar
              isDrawerOpen={isDrawerOpen}
              setIsDrawerOpen={setIsDrawerOpen}
              selected={"ADD"}
            />
          )}
          <div
            className='reservation-content'
            style={{ marginLeft: isDrawerOpen ? "16.25rem" : "5rem" }}
          >
            <TopBar
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              title='New Reservation'
              isOpenDrawer={isDrawerOpen}
            />
            <div className='reservation-view'>
              <div className='left-view'>
                <div className='guest-info' onClick={closeTabs}>
                  <div className='header'>
                    <img alt='' src={guestImg} />
                    Guest
                  </div>
                  <div className='name-container'>
                    <div className='left'>
                      <InputComponent
                        label=''
                        value={firstName}
                        onChange={(value) => {
                          handleChange("firstName");
                          setFirstName(value);
                        }}
                        disabled={false}
                        fontSize='0.75rem'
                        placeholder='First Name*'
                        isRequired
                      />
                      {errors.firstName && (
                        <span className='error'>{errors.firstName}</span>
                      )}
                    </div>
                    <div className='left'>
                      <InputComponent
                        label=''
                        value={lastName}
                        onChange={(value) => {
                          handleChange("lastName");
                          setLastName(value);
                        }}
                        disabled={false}
                        fontSize='0.75rem'
                        placeholder='Last Name*'
                        isRequired
                      />
                      {errors.lastName && (
                        <span className='error'>{errors.lastName}</span>
                      )}
                    </div>
                  </div>
                  <div className='name-container'>
                    <div className='left'>
                      <InputComponent
                        label=''
                        value={email}
                        onChange={(value) => {
                          handleChange("email");
                          setEmail(value);
                        }}
                        disabled={false}
                        fontSize='0.75rem'
                        placeholder='Email*'
                        isRequired
                      />
                      {errors.email && (
                        <span className='error'>{errors.email}</span>
                      )}
                    </div>
                    <div className='right'>
                      <NumberInputComponent
                        label=''
                        value={phoneNumber}
                        fontSize='0.75rem'
                        onChange={(value: number) => {
                          handleChange("phoneNumber");
                          setPhoneNumber(
                            value
                              .toString()
                              .replace("+44 ", "")
                              .replace("+", "")
                          );
                        }}
                      />
                      {errors.phoneNumber && (
                        <span className='error'>{errors.phoneNumber}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className='status-container' onClick={closeTabs}>
                  <div className='item'>
                    <div
                      className='check'
                      onClick={() => {
                        setWalkCheck(!walkCheck);
                      }}
                    >
                      {walkCheck && (
                        <div className='checked'>
                          <img alt='' src={tick} />
                        </div>
                      )}
                    </div>
                    <img alt='' src={walk} />
                    Walk-In
                  </div>
                  <div className='item'>
                    <div
                      className='check'
                      onClick={() => {
                        setWaitCheck(!waitCheck);
                      }}
                    >
                      {waitCheck && (
                        <div className='checked'>
                          <img alt='' src={tick} />
                        </div>
                      )}
                    </div>
                    <img alt='' src={wait} />
                    Wait List
                  </div>
                </div>
                <div className='inner-container'>
                  {filteredDetail.map((item) => {
                    return (
                      <div className='item-cont '>
                        {item.head === "Table" &&
                          isAlertVisible !== "" &&
                          getAlert()}
                        <div
                          className={`${
                            item.head === "Time" ||
                            item.head === "Party Size" ||
                            item.head === "Duration"
                              ? "item-def "
                              : "item"
                          } ${item.isOpen ? "item-sel" : ""}`}
                          onClick={() => {
                            if (
                              item.head === "Date" ||
                              item.head === "Status" ||
                              item.head === "Notes"
                            ) {
                              handleItemOnClick(item.head);
                            }
                          }}
                        >
                          <div className='det'>
                            <div
                              className={`head ${
                                item.isOpen ? "gradient-text" : ""
                              }`}
                            >
                              <img alt='' src={item.icon} />
                              {item.head}
                            </div>
                            <div className='sub'>{item.sub}</div>
                          </div>
                          {item.head !== "Party Size" &&
                            item.head !== "Duration" &&
                            item.head !== "Time" && (
                              <div
                                className={
                                  item.isOpen ? "arrow-cont-sel" : "arrow-cont"
                                }
                              >
                                <img alt='' src={item.isOpen ? arrow : back} />
                              </div>
                            )}
                        </div>

                        {isRequestOpen && (
                          <NotesModal
                            notes={request}
                            setNotes={setRequest}
                            isOpen={true}
                            onClose={() => {
                              setRequestOpen(false);
                            }}
                            isEdit={false}
                          />
                        )}
                        {isCommentsOpen && (
                          <CommentModal
                            comment={comments}
                            setComment={setComments}
                            isOpen={true}
                            onClose={() => {
                              setCommentsOpen(false);
                            }}
                          />
                        )}
                        {isDatOpen && (
                          <DateModal
                            date={date}
                            setDate={setDate}
                            setDateOpen={setDateOpen}
                            isOpen={isDatOpen}
                            onClose={() => {
                              setDateOpen(false);
                            }}
                            isEdit={false}
                          />
                        )}
                        {isStatusOpen && (
                          <StatusModal
                            isOpen={isStatusOpen}
                            onClose={() => {
                              setStatusOpen(false);
                            }}
                            setStatus={setStatusValue}
                            isEdit={false}
                          />
                        )}
                        {isTagOpen && (
                          <TagModal
                            isOpen={isTagOpen}
                            onClose={() => {
                              setTagOpen(false);
                            }}
                            setTags={setTagsData}
                            tags={tags}
                            isEdit={false}
                          />
                        )}
                        {isCoversOpen && (
                          <CoverModal
                            isOpen={isCoversOpen}
                            onClose={() => {
                              setCoversOpen(false);
                            }}
                            cover={guest}
                            setCover={setGuest}
                            isEdit={false}
                          />
                        )}
                        {isTimeOpen && (
                          <TimeModal
                            time={handleRoundTime(bookingTime)}
                            setTime={setBookingTime}
                            isOpen={isTimeOpen}
                            onClose={() => {
                              setTimeOpen(false);
                            }}
                            isEdit={false}
                            date={date}
                          />
                        )}
                        {isDurationOpen && (
                          <DurationModal
                            isOpen={isDurationOpen}
                            onClose={() => {
                              setDurationOpen(false);
                            }}
                            interval={interval}
                            setInterval={setInterval}
                            isEdit={false}
                          />
                        )}
                        {isWaitOpen && (
                          <EstModal
                            isOpen={isWaitOpen}
                            onClose={() => {
                              setWaitOpen(false);
                            }}
                            waitEst={waitESt}
                            setWaitEst={setWaitsEst}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className='btn-container'>
                  <Button
                    text='MAKE RESERVATION'
                    auth
                    onClick={handleSubmit}
                    loading={isLoading}
                  />
                </div>
              </div>
              <div className='right-view' onClick={closeTabs}>
                <FloorView
                  date={moment(date).format("YYYY-MM-DD")}
                  isView={false}
                  time={handleRoundTime(bookingTime)}
                />
              </div>
            </div>
          </div>
          <SuccessModal
            isOpen={isSuccess}
            onClose={() => {
              setIsSuccess(false);
            }}
            onOk={() => {
              navigate("/v1/booking/floor-view");
            }}
            header='Reservation successful!'
            content=' Your reservation has been successfully completed. Please refer to the
          floor view for detailed information about your booking.'
          />
          <TableErrorModal
            isOpen={isTableError}
            onClose={() => {
              dispatch(setAllocatedTables([]));
              setIsTableError(false);
            }}
            content={`The selected table is not available for the chosen booking time ${handleRoundTime(
              bookingTime
            )}. Please select a different table or time slot`}
          />
        </>
      ) : (
        <div className='landscape'>
          Please rotate your device to landscape mode
        </div>
      )}
    </div>
  );
};
export default NewReservation;
