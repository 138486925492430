import React, { useState, useEffect, useRef } from "react";
import "./ReservationStyles.scss";
import SideBar from "../../../components/ReservationComponents/sideBar/SideBar";
import TopBar from "../../../components/ReservationComponents/topBar/TopBar";

import { useNavigate } from "react-router-dom";
import Drawer from "../../../components/ReservationComponents/drawer/Drawer";
import Button from "../../../components/button/Button";
import SearchInput from "../../../components/ReservationComponents/searchInput/SearchInput";
import more from "../../../assets/more.png";
import deleteIc from "../../../assets/Delete.svg";
import shiftIc from "../../../assets/shiftIc.svg";
import ShiftManagementModal from "../../../components/ReservationComponents/shiftManagementModal/ShiftManagementModal";
import DeleteModal from "../../../components/ReservationComponents/errorModal/DeleteModal";
import { getShiftDetails } from "../../../../infra/apis/bookings/requests/Booking";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../application/store";
import { setShiftDetails } from "../../../../application/reducer/bookingSlice";
import { deleteShift } from "../../../../infra/apis/bookings/requests/ShiftManagement";
import SuccessModal from "../../../components/ReservationComponents/errorModal/SuccessModal";
import ShiftWarningModal from "../../../components/ReservationComponents/shiftManagementModal/ShiftWarningModal";

const ShiftManagement: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<any>(new Date());
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [width, setWidth] = useState(window.innerWidth - 200);
  const [value, setValue] = useState<string>("");
  const [openPopupIndex, setOpenPopupIndex] = useState<number | null>(null);
  const [isAddOpen, setIsAddOpen] = useState<boolean>(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [refId, setRefId] = useState<string>("");
  const [selectedShift, setSelectedShift] = useState<any>(null);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isUpdateSuccess, setIsUpdateSuccess] = useState<boolean>(false);
  const [isDeleteSuccess, setIsDeleteSuccess] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const dropdownRef = useRef<HTMLDivElement>(null);
  const locationId = useSelector(
    (state: RootState) => state.booking.locationId
  );
  const shiftData = useSelector(
    (state: RootState) => state.booking.shiftDetails
  );
  useEffect(() => {
    let userDetails = localStorage.getItem("userDetails")
      ? JSON.parse(localStorage.getItem("userDetails") || "")
      : "";
    if (userDetails === "" || userDetails === null) {
      navigate("/");
    } else {
      getData();
    }
  }, [navigate]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth - 200);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const getData = () => {
    setLoading(true);
    getShiftDetails(locationId).then((shiftData) => {
      dispatch(setShiftDetails(shiftData));
      dispatch(setShiftDetails(shiftData));
      setLoading(false);
    });
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setOpenPopupIndex(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const morePopup = (shift: any) => {
    if (openPopupIndex !== shift.ShiftManagementID) return null;
    return (
      <div className='dropdown-content' ref={dropdownRef}>
        <div
          className='item'
          onClick={() => {
            setIsDeleteOpen(true);
          }}
        >
          <img alt='' src={deleteIc} />
          Delete
        </div>

        <div
          className='item'
          onClick={() => {
            setSelectedShift(shift);
            console.log(shift, "shift");
            setIsEditOpen(true);
          }}
        >
          <img alt='' src={shiftIc} />
          Edit shift
        </div>
      </div>
    );
  };
  const filterByName = () => {
    if (value === "") {
      return shiftData;
    } else {
      return shiftData.filter((item: any) =>
        item.Name.toLowerCase().includes(value.toLowerCase())
      );
    }
  };
  return (
    <div className='Reservation'>
      {window.screen.width > 900 ? (
        <>
          {isDrawerOpen ? (
            <Drawer
              isDrawerOpen={isDrawerOpen}
              setIsDrawerOpen={setIsDrawerOpen}
              selectedMenu={"SHIFT MANAGEMENT"}
            />
          ) : (
            <SideBar
              isDrawerOpen={isDrawerOpen}
              setIsDrawerOpen={setIsDrawerOpen}
              selected={"SHIFT MANAGEMENT"}
            />
          )}
          <div
            className='reservation-content'
            style={{ marginLeft: isDrawerOpen ? "16.25rem" : "5rem" }}
          >
            <TopBar
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              title='Shift Management'
              isOpenDrawer={isDrawerOpen}
            />
            {loading ? (
              <div className='loader' />
            ) : (
              <div className='shift-view'>
                <div
                  className='shift-fixed-view'
                  style={{ width: isDrawerOpen ? width : "" }}
                >
                  <div className='btn-container'>
                    <Button
                      auth
                      text='New Shift'
                      height='34px'
                      padding='0.5rem 1rem'
                      fontWeight={500}
                      icon
                      onClick={() => {
                        setIsAddOpen(true);
                      }}
                    />
                    <div className='search-container'>
                      <SearchInput
                        value={value}
                        onChange={(e) => {
                          setValue(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className='table-view'>
                  <div className='table-grouping'>
                    <div className='grouping-header '>Recurring shifts</div>
                    {shiftData ? (
                      filterByName().length > 0 ? (
                        <div className='grouping-table'>
                          <table id='bookings'>
                            <thead>
                              <tr>
                                <th>Shift Name</th>
                                <th>Time Active</th>
                                <th>Days Active</th>
                                <th>Availability Type</th>
                                <th>Status</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {filterByName().map(
                                (value: any, index: number) => (
                                  <tr key={index} style={{ cursor: "pointer" }}>
                                    <td>{value.Name}</td>
                                    <td>
                                      {value.ActiveTime.First}-
                                      {value.ActiveTime.Last}
                                    </td>
                                    <td>
                                      <div className='dates'>
                                        {value?.ShiftActiveDay.map(
                                          (date: any, dateIndex: number) => (
                                            <div key={dateIndex}>
                                              {date.Day.toLowerCase().substring(
                                                0,
                                                3
                                              )}
                                              .
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </td>
                                    <td>Tables</td>
                                    <td>
                                      {value.IsActive ? (
                                        <div className='active'>Active</div>
                                      ) : (
                                        <div className='inactive'>Inactive</div>
                                      )}
                                    </td>
                                    <td>
                                      <div className='more-icon'>
                                        <img
                                          alt=''
                                          src={more}
                                          onClick={() => {
                                            setSelectedShift(value);
                                            setOpenPopupIndex(
                                              openPopupIndex ===
                                                value.ShiftManagementID
                                                ? null
                                                : value.ShiftManagementID
                                            );
                                            setRefId(value.ShiftManagementID);
                                          }}
                                        />
                                        {morePopup(value)}
                                      </div>
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className='empty-container'>
                          No shifts available for searched shift name
                          <span>{value}</span>
                        </div>
                      )
                    ) : (
                      <div className='empty-container'>No shifts available</div>
                    )}
                  </div>
                  {/* <div className='table-grouping'>
              <div className='grouping-header '>Temporary shifts</div>
              <div className='grouping-table'>
                <table id='shifts'>
                  <thead>
                    <tr>
                      <th>Shift Name</th>
                      <th>Time Active</th>
                      <th>Date Active</th>
                      <th>Availability Type</th>
                      <th>End date</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataShift.map((value, index) => (
                      <tr key={index} style={{ cursor: "pointer" }}>
                        <td>{value.name}</td>
                        <td>{value.timeActive}</td>
                        <td>
                          <div className='dates'>
                            {value.dateActive.map((date, dateIndex) => (
                              <div key={dateIndex}>{date}</div>
                            ))}
                          </div>
                        </td>
                        <td> {value.availabilityType}</td>
                        <td>{value.endDate}</td>
                        <td>{value.status}</td>
                        <td>
                          <div className='more-icon'>
                            <img
                              alt=''
                              src={more}
                              onClick={() => {
                                setOpenPopupIndex(
                                  openPopupIndex === value.id ? null : value.id
                                );
                              }}
                            />
                            {morePopup(value.id)}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div> */}
                </div>
              </div>
            )}
          </div>
          {isAddOpen && (
            <ShiftManagementModal
              onClose={() => {
                setIsAddOpen(false);
              }}
              update={false}
              setSuccess={setIsSuccess}
            />
          )}
          {isEditOpen && (
            <ShiftManagementModal
              onClose={() => {
                setIsEditOpen(false);
              }}
              update={true}
              data={selectedShift}
              setUpdateSuccess={setIsUpdateSuccess}
            />
          )}
          <DeleteModal
            isOpen={isDeleteOpen}
            onClose={() => {
              setRefId("");
              setIsDeleteOpen(false);
            }}
            onDelete={() => {
              setDeleteLoading(true);
              deleteShift(refId)
                .then((success) => {
                  if (success) {
                    setDeleteLoading(false);
                    setRefId("");
                    setIsDeleteOpen(false);
                    setIsDeleteSuccess(true);
                  } else {
                    setDeleteLoading(false);
                  }
                })
                .catch(() => {
                  setDeleteLoading(false);
                });
            }}
            isLoading={deleteLoading}
            content='Are you sure that you want to delete this shift?'
          />
          <SuccessModal
            isOpen={isSuccess}
            onClose={() => {
              window.location.reload();
              setIsSuccess(false);
            }}
            onOk={() => {
              window.location.reload();
              setIsSuccess(false);
            }}
            header='Successful!'
            content=' Successfully created a new shift'
          />
          <SuccessModal
            isOpen={isUpdateSuccess}
            onClose={() => {
              window.location.reload();
              setIsUpdateSuccess(false);
            }}
            onOk={() => {
              window.location.reload();
              setIsUpdateSuccess(false);
            }}
            header='Successfully Updated!'
            content='Successfully updated shift details'
          />
          <SuccessModal
            isOpen={isDeleteSuccess}
            onClose={() => {
              window.location.reload();
              setIsDeleteSuccess(false);
            }}
            onOk={() => {
              window.location.reload();
              setIsDeleteSuccess(false);
            }}
            header='Successfully Deleted!'
            content='Successfully shift deleted'
          />
          {/* <ShiftWarningModal /> */}
        </>
      ) : (
        <div className='landscape'>
          Please rotate your device to landscape mode
        </div>
      )}
    </div>
  );
};

export default ShiftManagement;
