import React from "react";
import "./ModalStyles.scss";

interface RenameModalProps {
  isOpen: boolean;
  onChange: () => void;
  onClose: () => void;
  isLoading: boolean;
  setName: React.Dispatch<React.SetStateAction<string>>;
  name: string;
}

const RenameModal: React.FC<RenameModalProps> = ({
  isOpen,
  onChange,
  onClose,
  isLoading,
  name,
  setName,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className='modal-overlay' onClick={onClose}>
      <div className='modal-content' onClick={(e) => e.stopPropagation()}>
        <div className='header'>Rename the room</div>

        <div className='input'>
          <input value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className='btn-container'>
          <div className='cancel' onClick={onClose}>
            Cancel
          </div>
          <div className='rename' onClick={onChange}>
            {isLoading ? <div className='loading-ic' /> : "Rename"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenameModal;
