import React from "react";
import "./TimeSlotStyles.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../application/store";
import {
  setTableDuration,
  setTime,
} from "../../../application/reducer/bookingSlice";
import { convertTime } from "../../../application/utils";

interface TimeSlotProps {
  timeSlots: string[];
  allDaySlots: string[];
}

const TimeSlot: React.FC<TimeSlotProps> = ({ timeSlots, allDaySlots }) => {
  const dispatch = useDispatch();
  const time = useSelector((state: RootState) => state.booking.time);
  const availableSpots = useSelector(
    (state: RootState) => state.booking.availableSpots
  );
  const chunkArray: any = (array: string[]) => {
    const result = [];
    for (let i = 0; i < array.length; i += 4) {
      result.push(array.slice(i, i + 4));
    }
    return result;
  };
  return (
    <div className='TimeSlot'>
      {chunkArray(allDaySlots).map((timeSlotarr: string[], index: number) => (
        <div className='timeslot-row' key={index}>
          {timeSlotarr.map((item: any, itemIndex) => {
            let slot = availableSpots.find(
              (slot: any) => convertTime(slot.StartTime) === item
            );
            let isAvailable = false;
            let isBefore = false;
            if (slot !== undefined) {
              isAvailable = slot ? slot.Available : false;
              let startTime = new Date(slot?.StartTime);
              let currentTime = new Date();
              let currentTimeUTC = new Date(
                startTime.getUTCFullYear(),
                startTime.getUTCMonth(),
                startTime.getUTCDate(),
                startTime.getUTCHours(),
                startTime.getUTCMinutes(),
                startTime.getUTCSeconds()
              );
              isBefore = currentTimeUTC < currentTime;
            }

            return (
              <div
                className={`single-slot ${
                  !timeSlots.includes(item)
                    ? "disable"
                    : !isAvailable
                    ? "disable"
                    : isBefore
                    ? "disable"
                    : item === time
                    ? "selected"
                    : ""
                } `}
                key={itemIndex}
                onClick={() => {
                  if (!isBefore && isAvailable) {
                    if (timeSlots.includes(item)) {
                      dispatch(setTime(item));
                      let duration = slot.Duration / 60;
                      dispatch(setTableDuration(duration));
                    }
                  }
                }}
              >
                <div className='time'>{item}</div>
                <div className='time-dif'></div>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};
export default TimeSlot;
