import React, { useState, useEffect, useRef } from "react";
import filter from "../../../assets/filter.png";
import down from "../../../assets/down.png";

interface FilterComponentProps {
  selectedFilter: string;
  setSelectedFilter: React.Dispatch<React.SetStateAction<string>>;
  selectedShift: string;
  setSelectedShift: React.Dispatch<React.SetStateAction<string>>;
  unsortedShifts: any[];
  view: string;
}

const FilterComponent: React.FC<FilterComponentProps> = ({
  selectedFilter,
  setSelectedFilter,
  selectedShift,
  setSelectedShift,
  unsortedShifts,
  view,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  const filterItem = (item: string) => {
    return selectedFilter === item ? (
      <div className='filter-selected'>
        <div
          className='filter'
          onClick={() => {
            if (selectedFilter !== item) {
              setSelectedFilter(item);
            } else {
              setSelectedFilter("");
            }
          }}
        >
          <div>{item}</div>
        </div>
      </div>
    ) : (
      <div
        className='filter'
        onClick={() => {
          setSelectedFilter(item);
        }}
      >
        {item}
      </div>
    );
  };
  const allShifts = () => {
    return (
      <div className='filter-dropdown' ref={dropdownRef}>
        <div
          className='filter-cont '
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {selectedShift}
          <img alt='' src={down} />
        </div>
        {isOpen && (
          <div className='dropdown-content'>
            {unsortedShifts.map((shift: any) => {
              return (
                <div
                  className='item'
                  onClick={() => {
                    setSelectedShift(shift.Name);
                    setIsOpen(false);
                  }}
                >
                  {shift.Name}
                </div>
              );
            })}
            <div
              className='item'
              onClick={() => {
                setSelectedShift("All Shifts");
                setIsOpen(false);
              }}
            >
              All Shifts
            </div>
          </div>
        )}
      </div>
    );
  };
  return (
    <div
      className={`left-container ${
        view === "LIST" ? "" : "left-conteinr-floor"
      }`}
    >
      {/* {view === "LIST" && (
        <div className='filter-ic'>
          <img alt='' src={filter} />
        </div>
      )} */}
      {filterItem("Seated")}
      {filterItem("Upcoming")}
      {allShifts()}
    </div>
  );
};
export default FilterComponent;
