import React from "react";
import "./ModalStyles.scss";
import img from "../../../assets/delete.png";
import Button from "../../button/Button";

interface SaveModalProps {
  isOpen: boolean;
  onClose: () => void;
  onOk: () => void;
  isLoading?: boolean;
  disable?: boolean;
}

const SaveModal: React.FC<SaveModalProps> = ({
  isOpen,
  onClose,
  onOk,
  isLoading,
  disable,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className='modal-overlay' onClick={onClose}>
      <div className='modal-content' onClick={(e) => e.stopPropagation()}>
        <div className='header'>Save Changes ?</div>

        <div className='content'>
          Your changes will be lose if you don't save them.
        </div>
        <div className='btn-container'>
          <div className='close' onClick={onClose}>
            Don't save
          </div>
          <Button
            text='Save'
            onClick={onOk}
            auth
            width='150px'
            loading={isLoading}
            disable={disable}
          />
        </div>
      </div>
    </div>
  );
};

export default SaveModal;
