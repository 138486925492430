import React, { useRef, useState, useEffect } from "react";
import "./FloorLayoutStyles.scss";
import { useDrop } from "react-dnd";
import { RootState } from "../../../../application/store";
import { useSelector, useDispatch } from "react-redux";
import TableComponent from "./Table/TableComponent";
import { v4 as uuidv4 } from "uuid";
import Button from "../../button/Button";
import {
  addTableToFloor,
  updateTableRecord,
} from "../../../../infra/apis/bookings/requests/FloorLayout";

interface FloorConfigLayoutProps {
  items: any;
  setSelectedFloor: React.Dispatch<React.SetStateAction<any>>;
  selectedFloor: any;
  addRoom?: () => void;
}

const FloorConfigLayout: React.FC<FloorConfigLayoutProps> = ({
  items,
  setSelectedFloor,
  selectedFloor,
  addRoom,
}) => {
  const [selectedTable, setSelectedTable] = useState<any>(null);
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  const locationId = useSelector(
    (state: RootState) => state.booking.locationId
  );
  const organizationDetails = useSelector(
    (state: RootState) => state.booking.organizationDetails
  );
  const floorLayoutData = useSelector(
    (state: RootState) => state.booking.floorLayoutData
  );
  const containerRef = useRef<HTMLDivElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setSelectedTable(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDraggedOver(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDraggedOver(false);
  };

  const [, drop] = useDrop({
    accept: "table",
    drop: (item: any, monitor) => {
      const containerRect = containerRef?.current?.getBoundingClientRect();
      const offset = monitor.getClientOffset();
      if (selectedFloor === null) return;
      if (!offset || !containerRect) return;
      let minusLeft = item.isNew ? 50 : item.Dimentions.Width;
      let minusTop = item.isNew ? 46.5 : item.Dimentions.Height;
      const left =
        offset.x - containerRect.left < minusLeft
          ? offset.x - containerRect.left
          : offset.x - containerRect.left - minusLeft;
      const top =
        offset.y - containerRect.top < minusTop
          ? offset.y - containerRect.top
          : offset.y - containerRect.top - minusTop;
      const selectedRoomTables = items ? [...items] : [];
      let newTable = {
        CloudLocationID: locationId,
        Covers: {
          Maximum: item.maxCovers,
          Minimum: 1,
        },
        Dimentions: {
          Angle: 0,
          Height: item.height,
          Shape: item.type,
          TableName: (selectedRoomTables.length + 1).toString(),
          Width: item.width,
          XValue: left,
          YValue: top,
        },
        IsOnline: true,
        OrganizationID: organizationDetails.ID,
        Priority: 1,
        TableName: (selectedRoomTables.length + 1).toString(),
        isNew: item.isNew,
        ID: uuidv4(),
        Floor: {
          CloudRefNo: selectedFloor?.FloorID,
        },
      };
      setIsDraggedOver(false);

      if (item.isNew) {
        selectedRoomTables.push(newTable);
        // addTableToFloor(newTable).then((response) => {
        //   console.log(response, "response");
        // });
      } else {
        const index = selectedRoomTables.findIndex(
          (table) => table.ID === item.ID
        );

        if (index !== -1) {
          const updatedTable = {
            ...item,
            Dimentions: {
              ...item.Dimentions,
              XValue: left,
              YValue: top,
            },
          };
          selectedRoomTables[index] = updatedTable;
          // updateTableRecord(updatedTable).then((response) => {
          //   console.log(response, "res");
          // });
        }
      }

      let Tables = selectedRoomTables;
      let updateSelectedRoom = { ...selectedFloor, Tables };
      setSelectedFloor(updateSelectedRoom);
    },
  });
  return floorLayoutData.length > 0 ? (
    <div
      className={`FloorLayout ${
        items.length > 0 || !isDraggedOver ? "" : "FloorLayout-empty"
      }`}
      ref={(node) => {
        drop(node);
        if (containerRef) {
          containerRef.current = node;
        }
      }}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
    >
      {items.length > 0 || !isDraggedOver ? (
        <div ref={dropdownRef}>
          {items?.map((item: any, index: number) => {
            return (
              <TableComponent
                item={item}
                setSelectedTable={setSelectedTable}
                selectedTable={selectedTable}
                setSelectedFloor={setSelectedFloor}
                selectedFloor={selectedFloor}
              />
            );
          })}
        </div>
      ) : (
        <div className='empty-cont'>Drag and drop your tables here</div>
      )}
    </div>
  ) : (
    <div className='FloorLayout-blank'>
      <div className='empty-floor'>
        <div className='text'>
          You do not have any rooms added, let's start by adding a room
        </div>
        <Button
          text='Add Room'
          icon
          width='220px'
          height='34px'
          auth
          onClick={addRoom}
        />
      </div>
    </div>
  );
};

export default FloorConfigLayout;
