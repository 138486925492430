import React, { useState, useEffect } from "react";
import "./ShiftManagementModalStyles.scss";
import img from "../../../assets/close-circle.svg";
import Button from "../../button/Button";
import InputComponent from "../../inputcomponent/InputComponent";
// import InputDatePicker from "../inputDatePicker/InputDatePicker";
import warning from "../../../assets/alert.png";
import Alert from "../../../pages/auth/reservation/tableDetails/Alert";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../application/store";
import {
  addShiftDetails,
  updateShiftDetails,
} from "../../../../infra/apis/bookings/requests/ShiftManagement";
interface ShiftManagementModalProps {
  onClose: () => void;
  update: boolean;
  data?: any;
  setSuccess?: React.Dispatch<React.SetStateAction<boolean>>;
  setUpdateSuccess?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ShiftManagementModal: React.FC<ShiftManagementModalProps> = ({
  onClose,
  update,
  data,
  setSuccess,
  setUpdateSuccess,
}) => {
  const [name, setName] = useState<string>("");
  //   const [startDate, setStartDate] = useState<any>(new Date());
  //   const [endDate, setEndDate] = useState<any>(new Date());
  const [selectedShiftDays, setSelectedShiftDays] = useState<any>([]);
  const [startTime, setStartTime] = useState<string>("00:00");
  const [endTime, setEndTime] = useState<string>("00:00");
  const [timeSlots, setTimeSlots] = useState<string>("15 mins");
  const [noticePeriod, setNoticePeriod] = useState<string>("0 mins");
  const [duration, setDuration] = useState<string>("1h");
  const [checked, setChecked] = useState<boolean>(true);
  const [min, setMin] = useState<any>("");
  const [max, setMax] = useState<any>("");
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [durationChecked, setDurationChecked] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [notiOpen, setNotiOpen] = useState<boolean>(false);

  const [errors, setErrors] = useState({
    name: "",
    activeDays: "",
    min: "",
    max: "",
    exceed: "",
    timeExceeds: "",
  });
  const organizationDetails = useSelector(
    (state: RootState) => state.booking.organizationDetails
  );
  const locationId = useSelector(
    (state: RootState) => state.booking.locationId
  );
  const openingHours = organizationDetails?.OpeningHours?.filter(
    (day: any) => !day.Closed
  ).map((day: any) => day.Day.toLowerCase());

  const shiftsDays = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  const noticePeriods = [
    "0 mins",
    "15 mins",
    "30 mins",
    "45 mins",
    "1h",
    "1h 30mins",
    "2h",
    "2h 30mins",
    "3h",
    "4h",
    "5h",
    "6h",
    "7h",
    "8h",
    "9h",
    "10h",
    "11h",
    "12h",
    "13h",
    "14h",
    "15h",
    "16h",
    "17h",
    "18h",
    "19h",
    "20h",
    "21h",
    "22h",
    "1 day",
    "2 days",
    "3 days",
    "4 days",
    "5 days",
  ];
  const generateTimeIntervals = () => {
    const intervals: number[] = [];
    const totalMinutes = 2 * 60;

    for (let minutes = 0; minutes <= totalMinutes; minutes += 15) {
      if (minutes !== 0) {
        intervals.push(minutes);
      }
    }

    return intervals;
  };
  const generateTimeSlots = (): string[] => {
    const timeSlots: string[] = [];
    let hours = 0;
    let minutes = 0;

    while (hours < 24) {
      const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
      timeSlots.push(formattedTime);

      minutes += 15;
      if (minutes === 60) {
        minutes = 0;
        hours += 1;
      }
    }

    return timeSlots;
  };
  const generateDurationIntervals = () => {
    const intervals: number[] = [];
    const totalMinutes = 5 * 60;

    for (let minutes = 0; minutes <= totalMinutes; minutes += 15) {
      if (minutes !== 0) {
        intervals.push(minutes);
      }
    }

    return intervals;
  };
  const formatTime = (minutes: number): string => {
    const days = Math.floor(minutes / (60 * 24));
    const hours = Math.floor((minutes % (60 * 24)) / 60);
    const remainingMinutes = minutes % 60;

    return `${days > 0 ? `${days}d ` : ""}${hours > 0 ? `${hours}h ` : ""}${
      remainingMinutes > 0 ? `${remainingMinutes} mins` : ""
    }`.trim();
  };

  const isShiftInclude = (value: any) => {
    return selectedShiftDays?.some((shift: any) => shift === value);
  };
  const handleStartChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    handleChange("timeExceeds");

    setStartTime(e.target.value);
  };
  const handleEndChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    handleChange("timeExceeds");

    setEndTime(e.target.value);
  };
  const handleTimeSlots = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTimeSlots(e.target.value);
  };
  const handleNoticePeriods = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setNoticePeriod(e.target.value);
  };
  const handleDuration = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setDuration(e.target.value);
  };
  const convertToMinute = (time: any) => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  };
  useEffect(() => {
    console.log(data, update);
    if (update && data !== null) {
      console.log("kk");
      setName(data?.Name);
      setStartTime(data?.ActiveTime.First);
      setEndTime(data?.ActiveTime.Last);
      if (data?.ReservationDuration !== 60) {
        setDurationChecked(true);
      } else {
        setDurationChecked(false);
      }
      setDuration(formatTime(data?.ReservationDuration));
      setTimeSlots(formatTime(data?.ShiftAvailability?.Timeslot));
      setNoticePeriod(formatTime(data?.ShiftAvailability?.NoticePeriod));
      setMax(data?.ShiftAvailability.ReservationCover.Maximum.toString());
      setMin(data?.ShiftAvailability.ReservationCover.Minimum.toString());
      setChecked(data?.IsActive ? true : false);
      let days = data?.ShiftActiveDay.map((day: any) => day.Day.toLowerCase());
      setSelectedShiftDays(days);
    }
  }, [data, update]);
  const isTimePassed = (start: any, end: any) => {
    const startMinutes = convertToMinute(start);
    const endMinutes = convertToMinute(end);

    return startMinutes >= endMinutes;
  };

  const validateForm = (): boolean => {
    let valid = true;
    const newErrors = {
      name: "",
      activeDays: "",
      min: "",
      max: "",
      exceed: "",
      timeExceeds: "",
    };
    if (name.trim() === "") {
      newErrors.name = "Name is required";
      valid = false;
    }
    if (min === "") {
      newErrors.min = "Minimum is required";
      valid = false;
    }
    if (min === "0") {
      newErrors.min = "The number of covers cannot be 0";
      valid = false;
    }
    if (max === "") {
      newErrors.max = " Maxium is required";
      valid = false;
    }
    if (max === "0") {
      newErrors.max = "The number of covers cannot be 0";
      valid = false;
    }
    if (Number(min) > Number(max)) {
      newErrors.exceed =
        "The minimum number of covers should be less than the maximum.";
      valid = false;
    }
    if (selectedShiftDays.length === 0) {
      newErrors.activeDays = "Please select active days";
      valid = false;
    }
    if (
      isTimePassed(startTime, endTime) &&
      startTime !== "00:00" &&
      endTime !== "00:00"
    ) {
      newErrors.timeExceeds = `${startTime} has passed ${endTime}`;
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };
  const handleShift = () => {
    let days = selectedShiftDays.map((day: any) => ({
      Day: day.toUpperCase(),
      StartTime: startTime,
      EndTime: endTime,
    }));
    let object = {
      ID: "",
      ShiftManagementID: data ? data?.ShiftManagementID : "",
      OrganizationID: organizationDetails.OrganizationDetails.ID,
      CloudLocationID: locationId,
      Name: name,
      Type: "RECURRING",
      ShiftActiveDay: days,
      ActiveTime: { First: startTime, Last: endTime },
      ShiftAvailability: {
        Type: "TABLES",
        ReservationCover: { Minimum: Number(min), Maximum: Number(max) },
        Timeslot: convertToMinutes(timeSlots),
        NoticePeriod: convertToMinutes(noticePeriod),
      },
      ReservationDuration: convertToMinutes(duration),
      Color: "#FF5733",
      IsActive: checked,
      IsDeleted: false,
    };
    if (validateForm()) {
      setLoading(true);
      if (update) {
        updateShiftDetails(object)
          .then((success) => {
            if (success) {
              setLoading(false);
              onClose();
              if (setUpdateSuccess) {
                setUpdateSuccess(true);
              }
            } else {
              setLoading(false);
              clearValues();
            }
          })
          .catch(() => {
            setLoading(false);
            clearValues();
          });
      } else {
        addShiftDetails(object)
          .then((success) => {
            if (success) {
              setLoading(false);
              onClose();
              if (setSuccess) {
                setSuccess(true);
              }
            } else {
              setLoading(false);
              clearValues();
            }
          })
          .catch(() => {
            setLoading(false);
            clearValues();
          });
      }
    }
  };
  const convertToMinutes = (period: string): number => {
    if (period.includes("day")) {
      return parseInt(period) * 24 * 60;
    }
    let totalMinutes = 0;

    // Use a regular expression to extract the numbers and their units
    const timeParts = period.match(/(\d+)\s*(h|min)/g);

    if (timeParts) {
      timeParts.forEach((part) => {
        if (part.includes("h")) {
          const hours = parseInt(part.replace("h", ""), 10);
          totalMinutes += hours * 60;
        } else if (part.includes("min")) {
          const minutes = parseInt(part.replace("mins", ""), 10);
          totalMinutes += minutes;
        }
      });
    }

    return totalMinutes;
  };
  const handleChange = (name: any) => {
    setErrors({
      ...errors,
      [name]: "",
    });
  };
  const clearValues = () => {
    setName("");
    setStartTime("00:00");
    setEndTime("00:00");
    setDurationChecked(false);
    setDuration("1h");
    setTimeSlots("15 mins");
    setNoticePeriod("0 mins");
    setMax("");
    setMin("");
    setChecked(true);
    setSelectedShiftDays([]);
  };

  return (
    <div className='shift-modal-overlay'>
      <div className='shift-modal-content' onClick={(e) => e.stopPropagation()}>
        <div className='container'>
          <div className='header'>
            {update ? <div>Edit Shift</div> : <div>Create a New Shift</div>}
            <img
              alt=''
              src={img}
              onClick={() => {
                clearValues();
                setErrors({
                  name: "",
                  activeDays: "",
                  min: "",
                  max: "",
                  exceed: "",
                  timeExceeds: "",
                });
                onClose();
              }}
            />
          </div>
          <div className='content'>
            <div className='active'>
              Shift active
              <label className='switch'>
                <input
                  type='checkbox'
                  checked={checked}
                  onClick={() => {
                    setChecked(!checked);
                  }}
                />
                <span className='slider round'></span>
              </label>
            </div>
            <div className='input'>
              <InputComponent
                label='Name your Shift'
                value={name}
                onChange={(value) => {
                  handleChange("name");

                  setName(value);
                }}
                disabled={false}
                fontSize='14px'
                margin
                isRequired
              />
              <div className='error'>{errors.name}</div>
            </div>
            {/* <div className='date-container'>
              <div className='start'>
                Start Date
                <InputDatePicker
                  selectedDate={startDate}
                  setSelectedDate={setStartDate}
                />
              </div>
              <div className='end'>
                End Date
                <InputDatePicker
                  selectedDate={endDate}
                  setSelectedDate={setEndDate}
                />
              </div>
            </div> */}
            <div className='shift-days'>
              <div className='header-tp'>
                What days is this shift active on?
                <span style={{ color: "red", marginLeft: "0.2rem" }}>*</span>
                <div
                  className='alert'
                  onClick={() => {
                    setNotiOpen(!notiOpen);
                  }}
                >
                  <img alt='' src={warning} />
                </div>
              </div>
              {notiOpen && (
                <div className='closed'>
                  Restaurant is closed on{" "}
                  {organizationDetails?.OpeningHours?.filter(
                    (day: any) => day.Closed
                  )
                    .map((day: any) => day.Day)
                    .join(", ")}
                </div>
              )}
              <div className='shifts-cont'>
                {shiftsDays.map((day: any) => {
                  return (
                    <div
                      className={
                        !openingHours.includes(day)
                          ? "shift-disable "
                          : isShiftInclude(day)
                          ? "shift-selected"
                          : "shift"
                      }
                      onClick={() => {
                        if (openingHours.includes(day)) {
                          handleChange("activeDays");
                          if (!isShiftInclude(day)) {
                            setSelectedShiftDays([...selectedShiftDays, day]);
                          } else {
                            let newArr = selectedShiftDays.filter(
                              (shift: any) => shift !== day
                            );
                            setSelectedShiftDays(newArr);
                          }
                        }
                      }}
                    >
                      {day.substring(0, 3)}.
                    </div>
                  );
                })}
              </div>
              <div className='error'>{errors.activeDays}</div>
            </div>
            <div className='time-container'>
              <div className='start'>
                <div>First accepted reservation time</div>
                <select
                  id='dates'
                  value={startTime}
                  onChange={handleStartChange}
                >
                  {generateTimeSlots().map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className='start'>
                <div>Last accepted reservation time</div>
                <select id='dates' value={endTime} onChange={handleEndChange}>
                  {generateTimeSlots().map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='error'>{errors.timeExceeds}</div>

            {/* <div className='type-container'>
              <div>Availability type</div>
              <div className='input'>Tables</div>
            </div> */}
          </div>
        </div>
        <div className='config'>
          <div className='header'>Table configuration</div>
          <div className='time-container'>
            <div className='start'>
              <div>
                Min. covers per reservation
                <span style={{ color: "red", marginLeft: "0.2rem" }}>*</span>
              </div>
              <input
                type='number'
                className='number'
                value={min}
                onChange={(e) => {
                  handleChange("min");
                  handleChange("exceed");

                  setMin(e.target.value);
                }}
                min={1}
              />
              <div className='error'>{errors.min}</div>
            </div>
            <div className='start'>
              <div>
                Max. covers per reservation
                <span style={{ color: "red", marginLeft: "0.2rem" }}>*</span>
              </div>
              <input
                type='number'
                className='number'
                value={max}
                onChange={(e) => {
                  handleChange("max");
                  handleChange("exceed");

                  setMax(e.target.value);
                }}
                min={1}
              />
              <div className='error'>{errors.max}</div>
            </div>
          </div>
          <div className='error'>{errors.exceed}</div>

          <div className='time-container'>
            <div className='start'>
              <div>Time slot</div>
              <select id='dates' value={timeSlots} onChange={handleTimeSlots}>
                {generateTimeIntervals().map((option) => (
                  <option key={option} value={formatTime(option)}>
                    {formatTime(option)}
                  </option>
                ))}
              </select>
            </div>
            <div className='start'>
              <div>Notice period</div>
              <select
                id='dates'
                value={noticePeriod}
                onChange={handleNoticePeriods}
              >
                {noticePeriods.map((option: any) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='duration-container'>
            <div className='start'>
              <div>Duration of reservations</div>
              {!durationChecked ? (
                <div className='input'>1h</div>
              ) : (
                <select id='dates' value={duration} onChange={handleDuration}>
                  {generateDurationIntervals().map((option) => (
                    <option key={option} value={formatTime(option)}>
                      {formatTime(option)}
                    </option>
                  ))}
                </select>
              )}
            </div>
            <div className='end'>
              <div className='left'>
                <label className='switch'>
                  <input
                    type='checkbox'
                    checked={durationChecked}
                    onClick={() => {
                      setDurationChecked(!durationChecked);
                      setDuration("1h");
                    }}
                  />
                  <span className='slider round'></span>
                </label>
                <div className='text'>Customize reservation durations.</div>
              </div>
              <div className='right'>
                <div
                  className='alert'
                  onClick={() => {
                    setAlertOpen(true);
                  }}
                >
                  <img alt='' src={warning} />
                </div>
              </div>
            </div>
          </div>
          {alertOpen && (
            <Alert
              type='warning'
              content='Default duration will be one hour'
              closeIcon
              onClick={() => {
                setAlertOpen(false);
              }}
            />
          )}
          <div className='btn-cont'>
            <Button
              auth
              text={update ? "Update Shift" : "Create Shift"}
              height='40px'
              padding='0.5rem 1rem'
              fontWeight={500}
              width='120px'
              onClick={handleShift}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShiftManagementModal;
