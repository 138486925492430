import React from "react";
import "./ModalStyles.scss";
import img from "../../../assets/delete.png";
import Button from "../../button/Button";

interface DeleteModalProps {
  isOpen: boolean;
  onDelete: () => void;
  onClose: () => void;
  isLoading: boolean;
  content: string;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  isOpen,
  onDelete,
  onClose,
  isLoading,
  content,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className='modal-overlay' onClick={onClose}>
      <div className='modal-content' onClick={(e) => e.stopPropagation()}>
        <div className='header'>
          <img alt='' src={img} className='error' />
          Delete confirmation
        </div>

        <div className='content-2'>{content}</div>
        <div className='btn-container'>
          <div className='cancel' onClick={onClose}>
            Cancel
          </div>
          <div className='delete' onClick={onDelete}>
            {isLoading ? <div className='loading-ic' /> : "Delete"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
