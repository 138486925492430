import React, { useEffect, useRef, useState } from "react";
import menu from "../../../assets/menu.svg";
import copy from "../../../assets/copy.svg";
import trash from "../../../assets/trash.svg";
import rename from "../../../assets/rename.svg";
import { useDrag } from "react-dnd";

interface RoomLayoutProps {
  floor: any;
  selectedFloor: any;
  setSelectedFloor: React.Dispatch<React.SetStateAction<any>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setIsRenameOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDeleteOpen: React.Dispatch<React.SetStateAction<boolean>>;

  duplicateRoom: () => void;
  index: number;
}
const RoomLayout: React.FC<RoomLayoutProps> = ({
  floor,
  selectedFloor,
  setSelectedFloor,
  duplicateRoom,
  setName,
  setIsDeleteOpen,
  setIsRenameOpen,
  index,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const [, ref] = useDrag({
    type: "Room",
    item: { index, id: floor.FloorID, floor },
  });
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  return (
    <div className='cont' ref={ref}>
      <div
        className={`content ${
          selectedFloor?.FloorID === floor.FloorID ? "content-selected " : ""
        }`}
        onClick={() => {
          setSelectedFloor(floor);
        }}
      >
        {floor.DisplayName}
      </div>
      {selectedFloor?.FloorID === floor.FloorID && (
        <div
          onClick={() => {
            setIsOpen(!isOpen);
            console.log("jjj");
          }}
          className='menu-ic'
        >
          <img alt='' src={menu} className='menu' />
        </div>
      )}
      {isOpen && selectedFloor?.FloorID === floor.FloorID && (
        <div className='dropdown' ref={dropdownRef}>
          <div
            className='item'
            onClick={() => {
              setName(selectedFloor?.Name);
              setIsOpen(false);
              setIsRenameOpen(true);
            }}
          >
            <img alt='' src={rename} className='menu' />
            Rename Room
          </div>
          <div
            className='item'
            onClick={() => {
              setIsOpen(false);
              duplicateRoom();
            }}
          >
            <img alt='' src={copy} className='menu' />
            Duplicate Room
          </div>{" "}
          <div
            className='item'
            onClick={() => {
              setIsOpen(false);
              setIsDeleteOpen(true);
            }}
          >
            <img alt='' src={trash} className='menu' />
            Delete Room
          </div>
        </div>
      )}
    </div>
  );
};
export default RoomLayout;
