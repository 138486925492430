import React from "react";
import "./TimeModalStyles.scss";
import close from "../../../../assets/close.png";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../application/store";
import moment from "moment";

interface TimeModalProps {
  isOpen: boolean;
  onClose: () => void;
  time: string;
  setTime: React.Dispatch<React.SetStateAction<string>>;
  isEdit: boolean;
  date: any;
}

const TimeModal: React.FC<TimeModalProps> = ({
  isOpen,
  onClose,
  time,
  setTime,
  isEdit,
  date,
}) => {
  const shiftData = useSelector(
    (state: RootState) => state.booking.shiftDetails
  );

  // Generate timeslots from 04:00 to 04:00 in 15-minute intervals (24-hour format)
  const generateTimeslots = (): string[] => {
    const timeslots: string[] = [];
    let startHour = 4;
    let startMinute = 0;

    while (true) {
      const hourString = startHour.toString().padStart(2, "0");
      const minuteString = startMinute.toString().padStart(2, "0");

      timeslots.push(`${hourString}:${minuteString}`);

      startMinute += 15;
      if (startMinute === 60) {
        startMinute = 0;
        startHour += 1;
      }

      if (startHour === 24) {
        startHour = 0;
      }

      if (startHour === 4 && startMinute === 0) {
        break;
      }
    }

    return timeslots;
  };

  // Convert time string to minutes since midnight
  const timeToMinutes = (time: string): number => {
    const [hour, minute] = time.split(":").map(Number);
    return hour * 60 + minute;
  };

  // Get timeslots between two times
  const getTimeslotsBetween = (
    startTime: string,
    endTime: string
  ): string[] => {
    const startMinutes = timeToMinutes(startTime);
    const endMinutes = timeToMinutes(endTime);
    const result: string[] = [];
    for (const slot of generateTimeslots()) {
      const slotMinutes = timeToMinutes(slot);
      if (slotMinutes >= startMinutes && slotMinutes <= endMinutes) {
        result.push(slot);
      }
    }
    return result;
  };

  // Create a new array with shift names and their timeslots
  const shiftTimeslots = shiftData
    .map((shift: any) => {
      let active = shift.ShiftActiveDay.some(
        (day: any) => day.Day === moment(date).format("dddd").toUpperCase()
      );
      if (active) {
        if (shift.ActiveTime?.First && shift.ActiveTime?.Last) {
          return {
            Name: shift.Name,
            Timeslots: getTimeslotsBetween(
              shift.ActiveTime.First,
              shift.ActiveTime.Last
            ),
            Covers: `${shift.ShiftAvailability}`,
          };
        } else {
          console.error(`Missing ActiveTime for shift: ${shift.Name}`);
          return {
            Name: shift.Name,
            Timeslots: [],
          };
        }
      }
      return null;
    })
    .filter((shift: any) => shift !== null);

  // Generate all possible timeslots
  const allTimeslots = generateTimeslots();

  // Create a map to track timeslot assignments
  const timeslotMap: { [key: string]: { Name: string; Timeslots: string[] } } =
    {};

  // Initialize the map with empty arrays for all timeslots
  allTimeslots.forEach((slot) => {
    timeslotMap[slot] = { Name: "empty", Timeslots: [slot] };
  });

  // Fill in the map with shift data
  shiftTimeslots.forEach((shift: any) => {
    shift.Timeslots.forEach((slot: any) => {
      if (timeslotMap[slot]) {
        timeslotMap[slot] = { Name: shift.Name, Timeslots: [slot] };
      }
    });
  });

  // Convert the map back to an array
  const combinedTimeslots = Object.values(timeslotMap);

  // Group timeslots and show shift names only once at the beginning
  const groupedTimeslots = combinedTimeslots.reduce((acc: any[], current) => {
    if (acc.length === 0 || acc[acc.length - 1].Name !== current.Name) {
      acc.push({ Name: current.Name, Timeslots: [current.Timeslots[0]] });
    } else {
      acc[acc.length - 1].Timeslots.push(current.Timeslots[0]);
    }
    return acc;
  }, []);
  return (
    <div className='time-modal-overlay' onClick={onClose}>
      <div
        className='time-modal-content'
        onClick={(e) => e.stopPropagation()}
        style={{ left: isEdit ? "37.2%" : "", top: isEdit ? "2rem" : "" }}
      >
        <div className='header'>
          <img alt='Close' src={close} onClick={onClose} />
        </div>
        <div className='content'>
          {shiftTimeslots.map((item: any, index: number) => (
            <React.Fragment key={index}>
              {item.Name !== "empty" && (
                <div className='shift-name'>
                  <div className='gradient-text'>{item.Name}</div>
                </div>
              )}
              {item.Timeslots.map((slot: any, i: number) => (
                <div
                  className={`item-duration ${slot === time ? "selected" : ""}`}
                  key={i}
                  onClick={() => {
                    setTime(slot);
                    onClose();
                  }}
                >
                  <div className='gradient-text'>{slot}</div>
                </div>
              ))}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TimeModal;
