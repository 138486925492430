import React, { useEffect, useState } from "react";
import "./TableGroupingModalStyles.scss";
import img from "../../../assets/close-circle.svg";
import Button from "../../button/Button";
import InputComponent from "../../inputcomponent/InputComponent";
import AddGroupingDropdown from "../../groupingDropDown/AddGroupingDropdown";
import { useSelector } from "react-redux";
import { RootState } from "../../../../application/store";
import { addManualTableMerge } from "../../../../infra/apis/bookings/requests/TableMerging";
import UpdateGroupingDropdown from "../../groupingDropDown/UpdateGroupingDropdown";
import Alert from "../../../pages/auth/reservation/tableDetails/Alert";
import CoversInput from "../numberInput/CoversInput";

interface TableGroupingModalProps {
  isOpen: boolean;
  onClose: () => void;
  tableData: any;
  selected: any;
  setSelected: React.Dispatch<React.SetStateAction<any>>;
  updateData?: any;
  isEdit: boolean;
  setSuccess?: React.Dispatch<React.SetStateAction<boolean>>;
  setUpdateSuccess?: React.Dispatch<React.SetStateAction<boolean>>;
  data: any[];
}

const TableGroupingModal: React.FC<TableGroupingModalProps> = ({
  isOpen,
  onClose,
  tableData,
  selected,
  setSelected,
  updateData,
  isEdit,
  setSuccess,
  setUpdateSuccess,
  data,
}) => {
  const [name, setName] = useState<string>("");
  const [min, setMin] = useState<any>("1");
  const [max, setMax] = useState<any>("4");
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState({
    min: "",
    max: "",
    exceed: "",
    tables: "",
  });
  const organizationDetails = useSelector(
    (state: RootState) => state.booking.organizationDetails
  );
  const [errorLoading, setErrorLoading] = useState<boolean>(false);

  const isArrayIncluded = () => {
    let val = false;
    let existingGroupings = data.map(
      (value) => value.MergedTables.map((obj: any) => obj.Id) || []
    );
    let selectedGroupings = selected.map((value: any) => value.Id || value.ID);

    if (isEdit) {
      let array = updateData.MergedTables.map((obj: any) => obj.Id) || [];
      const updatedArray = existingGroupings.filter(
        (arr) => JSON.stringify(arr) !== JSON.stringify(array)
      );
      val = updatedArray.some((subArray) =>
        subArray.every((item: any) => selectedGroupings.includes(item))
      );
    } else {
      val = existingGroupings.some((subArray) =>
        subArray.every((item: any) => selectedGroupings.includes(item))
      );
    }
    return val;
  };
  useEffect(() => {
    if (isEdit) {
      setName(updateData.GroupName);
      setMax(updateData.Cover.Maximum);
      setMin(updateData.Cover.Minimum);
      setSelected(updateData.MergedTables);
    }
  }, []);
  const handleAdd = () => {
    let data = {
      tableMergeID: isEdit ? updateData.TableMergeID : "",
      organizationID: organizationDetails.OrganizationDetails.ID,
      cover: {
        Minimum: Number(min),
        Maximum: Number(max),
      },
      displayName: name,
      mergedTables: selected,
    };
    if (validateForm()) {
      if (isArrayIncluded()) {
        setErrorLoading(true);
      } else {
        setLoading(true);
        addManualTableMerge(data)
          .then((success) => {
            if (success) {
              setLoading(false);
              onClose();
              if (isEdit && setUpdateSuccess) {
                setUpdateSuccess(true);
              }
              if (!isEdit && setSuccess) {
                setSuccess(true);
              }
            }
          })
          .catch(() => {
            setLoading(false);
            setErrorLoading(true);
          });
      }
    }
  };
  const validateForm = (): boolean => {
    let valid = true;
    const newErrors = {
      min: "",
      max: "",
      tables: "",
      exceed: "",
    };
    if (min === "") {
      newErrors.min = "Minimum is required";
      valid = false;
    }
    if (min === "0") {
      newErrors.min = "The number of covers cannot be 0";
      valid = false;
    }
    if (max === "") {
      newErrors.max = " Maxium is required";
      valid = false;
    }
    if (max === "0") {
      newErrors.max = "The number of covers cannot be 0";
      valid = false;
    }
    if (Number(min) > Number(max)) {
      newErrors.exceed =
        "The minimum number of covers should be less than the maximum.";
      valid = false;
    }
    if (selected.length === 0) {
      newErrors.tables = "*required";
      valid = false;
    }
    if (selected.length === 1) {
      newErrors.tables = "*please select two or more tables";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };
  const handleChange = (name: any) => {
    setErrors({
      ...errors,
      [name]: "",
    });
  };
  useEffect(() => {
    handleChange("tables");
    setErrorLoading(false);
  }, [selected]);
  return (
    <div className='table-modal-overlay'>
      <div className='table-modal-content' onClick={(e) => e.stopPropagation()}>
        <div className='container'>
          <div className='header'>
            {!isEdit ? (
              <div>Create a new table grouping</div>
            ) : (
              <div>Edit table grouping</div>
            )}
            <img alt='' src={img} onClick={onClose} />
          </div>
          <div className='content'>
            <InputComponent
              label='Group Name'
              value={name}
              onChange={(value) => {
                setName(value);
              }}
              disabled={false}
              fontSize='14px'
              isRequired={false}
            />
            <div className='covers-cont'>
              <div className='cvr-head'>Covers</div>
              <div className='min-max'>
                <div className='min'>
                  <div>
                    Minimum{" "}
                    <span style={{ color: "red", marginLeft: "0.2rem" }}>
                      *
                    </span>
                  </div>
                  <CoversInput
                    value={min}
                    onChange={() => {
                      handleChange("min");
                      handleChange("exceed");
                    }}
                    setValue={setMin}
                  />
                </div>
                <div className='max'>
                  <div>
                    Maximum
                    <span style={{ color: "red", marginLeft: "0.2rem" }}>
                      *
                    </span>
                  </div>
                  <CoversInput
                    value={max}
                    onChange={() => {
                      handleChange("max");
                      handleChange("exceed");
                    }}
                    setValue={setMax}
                  />
                </div>
              </div>
              <div className='error'>{errors.min}</div>
              <div className='error'>{errors.max}</div>
              <div className='error'>{errors.exceed}</div>
            </div>
          </div>
        </div>

        <div className='comb'>
          <div className='header'>
            <div>
              Select Table Combination
              <span style={{ color: "red", marginLeft: "0.2rem" }}>*</span>
            </div>
          </div>
          {tableData.map((value: any) => {
            if (!isEdit) {
              return (
                <AddGroupingDropdown
                  floor={value}
                  selected={selected}
                  setSelected={setSelected}
                />
              );
            } else {
              return (
                <UpdateGroupingDropdown
                  floor={value}
                  selected={selected}
                  setSelected={setSelected}
                />
              );
            }
          })}
          {errors.tables && <span className='error'>{errors.tables}</span>}
          {errorLoading && (
            <Alert type='danger' content='Selected combination exists' />
          )}
          <div className='btn-cont'>
            <Button
              auth
              text={isEdit ? "Update" : "Create"}
              height='40px'
              padding='0.5rem 1rem'
              fontWeight={500}
              width='90px'
              loading={loading}
              onClick={() => {
                handleAdd();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableGroupingModal;
