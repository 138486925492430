import React from "react";
import "./FloorLayoutStyles.scss";
import Table from "./Table/Table";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../application/store";
import { setAllocatedTables } from "../../../../application/reducer/bookingSlice";
import { get } from "http";

interface FloorLayoutProps {
  items: any;
  selected?: any;
  setSelected?: React.Dispatch<React.SetStateAction<any>>;
  isView?: boolean;
  time: string;
  isEdit?: boolean;
  date?: any;
}

const FloorLayout: React.FC<FloorLayoutProps> = ({
  items,
  selected,
  setSelected,
  isView,
  time,
  isEdit,
  date,
}) => {
  const dispatch = useDispatch();
  const allocatedTables = useSelector(
    (state: RootState) => state.booking.allocatedTables
  );
  const roundToNextQuarterHour = (time: moment.Moment): moment.Moment => {
    const minutes = time.minutes();
    const nextQuarterHour = Math.ceil(minutes / 15) * 15;
    if (nextQuarterHour === 60) {
      return time.add(1, "hour").startOf("hour").add(15, "minutes");
    }
    return time.minutes(nextQuarterHour).seconds(0);
  };
  const convertTimeToMinutes = (time: string): number => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  };
  const handleRoundTime = (timeStr: string) => {
    const time = moment(timeStr, "HH:mm");
    const rounded = roundToNextQuarterHour(time);
    return rounded.format("HH:mm");
  };
  const isTimeMatchingReservation = (
    time: string,
    reservations: any
  ): boolean => {
    const givenTimeInMinutes = convertTimeToMinutes(time);
    return reservations.some((reservation: any) => {
      const bookingTimeInMinutes = convertTimeToMinutes(
        reservation?.BookingDetails?.BookingTime
      );
      const endTimeInMinutes =
        bookingTimeInMinutes + reservation?.BookingDetails?.Duration;

      return (
        givenTimeInMinutes >=
          bookingTimeInMinutes - reservation?.BookingDetails?.Duration &&
        givenTimeInMinutes < endTimeInMinutes
      );
    });
  };
  const getStatusInfoForTime = (reservations: any) => {
    const givenTimeInMinutes = convertTimeToMinutes(time);
    if (new Date(date).toDateString() === new Date().toDateString()) {
      for (const reservation of reservations) {
        const bookingTimeInMinutes = convertTimeToMinutes(
          reservation.BookingDetails.BookingTime
        );
        const endTimeInMinutes =
          bookingTimeInMinutes + reservation.BookingDetails.Duration;
        if (
          givenTimeInMinutes >= bookingTimeInMinutes &&
          givenTimeInMinutes < endTimeInMinutes
        ) {
          return reservation.StatusInfo;
        }
      }
    }

    return null;
  };
  return (
    <div className='FloorLayout'>
      {items?.map((item: any, index: number) => {
        return (
          <div
            key={index}
            className='table-bg'
            style={{
              left: `${item?.Dimentions.XValue}px`,
              top: `${item?.Dimentions.YValue}px`,
              transform: `rotate(${item?.Dimentions.Angle}deg)`,
              height: item?.Dimentions.Height,
              width: item?.Dimentions.Width,
              backgroundColor: isView
                ? getStatusInfoForTime(item.Reservations)
                  ? `${getStatusInfoForTime(item.Reservations).Color}1A`
                  : ""
                : isEdit
                ? isTimeMatchingReservation(
                    handleRoundTime(time),
                    item.Reservations
                  ) &&
                  !selected.AssetAllocation.some(
                    (table: any) => table.RefNo === item.ID
                  )
                  ? "#FFDDDC"
                  : allocatedTables.some((table) => table.RefNo === item.ID)
                  ? "#EEE9FD"
                  : ""
                : isTimeMatchingReservation(
                    handleRoundTime(time),
                    item.Reservations
                  )
                ? "#FFDDDC"
                : allocatedTables.some((table) => table.RefNo === item.ID)
                ? "#EEE9FD"
                : "",
            }}
            onClick={() => {
              if (isView) {
                if (item && setSelected) {
                  setSelected(item);
                }
              } else {
                if (isEdit) {
                  if (
                    (isTimeMatchingReservation(
                      handleRoundTime(time),
                      item.Reservations
                    ) &&
                      selected.AssetAllocation.some(
                        (table: any) => table.RefNo === item.ID
                      )) ||
                    !isTimeMatchingReservation(
                      handleRoundTime(time),
                      item.Reservations
                    )
                  ) {
                    const statusExists = allocatedTables.some(
                      (table) => table.RefNo === item.ID
                    );

                    if (!statusExists) {
                      let obj = {
                        Capacity: {
                          Max: item?.Covers?.Maximum
                            ? item?.Covers?.Maximum
                            : 0,
                          Min: item?.Covers?.Minimum
                            ? item?.Covers?.Minimum
                            : 0,
                        },
                        LocalReference: {
                          Floor: {
                            Id: item.FloorID,
                          },
                          Table: {
                            Id: item.LocalID,
                          },
                        },
                        Name: item.TableName,
                        RefNo: item.ID,
                        Status: "CONFIRMED",
                        Type: "TABLE",
                      };

                      dispatch(setAllocatedTables([...allocatedTables, obj]));
                    } else {
                      let newArry = allocatedTables.filter(
                        (tableItem) => tableItem.RefNo !== item.ID
                      );
                      dispatch(setAllocatedTables(newArry));
                    }
                  }
                } else {
                  if (
                    !isTimeMatchingReservation(
                      handleRoundTime(time),
                      item.Reservations
                    )
                  ) {
                    const statusExists = allocatedTables.some(
                      (table) => table.RefNo === item.ID
                    );
                    if (!statusExists) {
                      let obj = {
                        Capacity: {
                          Max: item.Covers?.Maximum ? item.Covers?.Maximum : 0,
                          Min: item?.Covers?.Minimum ? item.Covers?.Minimum : 0,
                        },
                        LocalReference: {
                          Floor: {
                            Id: item.FloorID,
                          },
                          Table: {
                            Id: item.LocalID,
                          },
                        },
                        Name: item.TableName,
                        RefNo: item.ID,
                        Status: "CONFIRMED",
                        Type: "TABLE",
                      };

                      dispatch(setAllocatedTables([...allocatedTables, obj]));
                    } else {
                      let newArry = allocatedTables.filter(
                        (tableItem) => tableItem.RefNo !== item.ID
                      );
                      dispatch(setAllocatedTables(newArry));
                    }
                  }
                }
              }
            }}
          >
            <Table
              height={item?.Dimentions.Height}
              width={item.Dimentions.Width}
              chairCount={item?.Covers?.Maximum ? item?.Covers?.Maximum - 2 : 4}
              type={item.Dimentions.Shape}
              name={item.TableName}
              isLocked={
                isEdit
                  ? isTimeMatchingReservation(
                      handleRoundTime(time),
                      item.Reservations
                    ) &&
                    !selected.AssetAllocation.some(
                      (table: any) => table.RefNo === item.ID
                    ) &&
                    isTimeMatchingReservation(
                      handleRoundTime(time),
                      item.Reservations
                    )
                  : isTimeMatchingReservation(
                      handleRoundTime(time),
                      item.Reservations
                    )
              }
              status={getStatusInfoForTime(item.Reservations)}
              isView={isView !== undefined ? isView : false}
              isEdit={isEdit !== undefined ? isEdit : false}
              reservationCount={item.Reservations.length}
            />
          </div>
        );
      })}
    </div>
  );
};

export default FloorLayout;
